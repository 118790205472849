const Logo = require('../assets/images/logoDark.png');
const Contact = require('../assets/images/contactUs.png');
const Login = require('../assets/images/logIn.png');
const LogoFooter = require('../assets/images/blackbg.png');
const BGImg = require('../assets/images/bgImg.jpg');
const CardCover = require('../assets/images/cardCover.jpg');
const UserImg = require('../assets/images/user.png');
const TattooMachine = require('../assets/images/tattooMachine.png');
const AboutBusinessImg = require('../assets/images/aboutBusiness.jpg');
const AvatarImg = require('../assets/images/avatar.png');

export { Logo, Contact, Login, LogoFooter, BGImg, CardCover, TattooMachine, UserImg, AboutBusinessImg, AvatarImg };