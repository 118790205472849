import React, { useState, useEffect } from 'react';
import { Form, message, Upload } from 'antd';
import axios from "axios";
import { TATTOO_URL } from "../../../Helper";
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';

const StepThree = (props) => {
   const [loading, setLoading] = useState(false);
   const [imageUrl, setImageUrl] = useState();

   const normFile = (e) => {
      if (Array.isArray(e)) {
         return e;
      }
      return e?.fileList;
   };

   const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
   };

   const beforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

      if (!isJpgOrPng) {
         message.error("You can only upload JPG/PNG file!");
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
         message.error("Image must smaller than 2MB!");
      }

      return isJpgOrPng && isLt2M;
   };

   const handleChangeImage = (info) => {
      if (info.file.status === "uploading") {
         setLoading(true);
         return;
      }

      if (info.file.status === "done") {
         // Get this url from response in real world.
         getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
            props.profileInput.profile_photo = url;
         });
      }
   };

   const uploadButton = (
      <div>
         {loading ? <LoadingOutlined /> : <InboxOutlined />}
         <div
            style={{
               marginTop: 8,
            }}
         >
            Upload
         </div>
      </div>
   );

   const uploadImage = async (options) => {
      const { onSuccess, onError, file } = options;

      const fmData = new FormData();
      const config = {
         headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
         },
      };
      fmData.append("file", file);
      try {
         const res = await axios.post(TATTOO_URL + 'api/media_uploads', fmData, config);
         onSuccess("Ok");
         setImageUrl(res);
         props.profileInput.profile_photo_id = res?.data?.data?.id;
      } catch (err) {
         console.error("Error: ", err);
      }
   };

   useEffect(() => {
      setImageUrl(props.profileInput.profile_photo)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className='stepWrapper'>
         <div className='stepwrapInner'>
            <div className='stepTitle'>
               <h4>Add your profile photo</h4>
               <p>Your profile photo will appear on your profile and directory listing (Tattoo Artist search results). Make it a good one!</p>
            </div>

            <Form.Item
               name='profile_photo'
               valuePropName="fileList"
               getValueFromEvent={normFile}
               className='customDragger'
               rules={[{
                  required: true,
                  message: 'Please upload profile photo'
               }]}
            >
               <Upload
                  name="profile_photo"
                  listType="picture-card"
                  className="avatar-uploader"
                  multiple={false}
                  showUploadList={false}
                  customRequest={uploadImage}
                  beforeUpload={beforeUpload}
                  onChange={handleChangeImage}
               >
                  {imageUrl ? (
                     <img
                        src={imageUrl}
                        alt="profile_photo"
                        style={{
                           width: "100%",
                        }}
                     />
                  ) : (
                     uploadButton
                  )}
               </Upload>
            </Form.Item>
         </div>
      </div>
   );
}

export default StepThree;