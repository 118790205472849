import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Image,
  Rate,
  Button,
  notification,
  Spin,DatePicker
} from "antd";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../constant/images";
import { useDispatch } from "react-redux";
import * as getReviewService from "../../services/editWriteReview";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";

const EditReviewForm = () => {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [artistData, setArtistData] = useState();
  const [loadings, setLoadings] = useState([]);
  const [edittReview, setEdittReview] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const userID = queryParams.get("user_id");
  let customerId = localStorage.getItem("customer_id");
  let NewCustId = parseInt(customerId);
  let NewArtistId = parseInt(userID);
  const [newReview, setNewReview] = useState();
  const [newRating, setNewRating] = useState();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);

  const role = localStorage.getItem("auth_role");

  const handleReview = (e) => {
    let updatedReview = e.target.value;
    setNewReview(updatedReview);
  };
  const handleRating = (value) => {
    let updatedRating = value;
    setNewRating(updatedRating);
  };

  const reviewEdit = () => {
    setLoader(true);
    setOpen(true);
    let value = {
      user_profile_id: role === "Artist" ? NewCustId: NewArtistId,
      user_id:role === "Artist"? NewArtistId : NewCustId,
    };
    dispatch(getReviewService.editgetReviews(value))
      .then((res) => {
        setEdittReview(res.data.data);
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoader(false);
        setOpen(false);
      });
  };

  useEffect(() => {
    reviewEdit();
    window.scrollTo(0, 0);
  }, []);

  const onFinish = (values) => {
    if(role === "Artist"){
      setLoadings(true);

      const data = {
        review: newReview ? newReview : edittReview?.review,
        rating: newRating ? newRating : edittReview?.rating,
        user_profile_id:role === "Artist" ? NewCustId: NewArtistId,
        review_id: edittReview?.id,
      };
      dispatch(getReviewService.editReviews(data))
        .then((res) => {
          setLoadings(false);
          notification.success({
            message: "Review submitted",
          });
          navigate("/user-profile/?user_id=" + customerId);
        })
        .catch((err) => {
          console.info("Get Error Edit State...");
        });
    }else{
      setLoadings(true);
      const data = {
        review: newReview ? newReview : edittReview?.review,
        rating: newRating ? newRating : edittReview?.rating,
        user_profile_id: NewArtistId,
        user_id: NewCustId,
      };
      dispatch(getReviewService.editwriteReview(data))
        .then((res) => {
          setLoadings(false);
          notification.success({
            message: "Review submitted",
          });
          navigate("/user-profile/?user_id=" + userID);
        })
        .catch((err) => {
          console.info("Get Error Edit State...");
        });
    }
   
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  let Key = Math.floor(Math.random() * 1000);

  return (
    <>
      {loader === true ? (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <Spin size="large" />
          </Backdrop>
        </>
      ) : (
        ""
      )}
      <div className="wrireReviewWrapper">
        <div className="innerWrap">
          <div className="container-fluid">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col xs={24} md={24} xl={24}>
                <div className="titleRow">
                  <h3 className="pageTitle">Edit your review here</h3>
                </div>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify="center">
              <Col xs={24} md={16} xl={16}>
                <div className="reviewFormWrap">
                  <Card bordered={false} className="reviewForm">
                    <div className="cardBody">
                      <Form
                        name="reviewForm"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        initialValues={{
                          remember: true,
                        }}
                      >
                        <div className="topWrap">
                          <div className="profileWrap">
                            <div className="imgSection">
                              <Image
                                src={
                                  artistData?.user_profile?.profile_photo_id
                                    ? artistData?.user_profile?.profile_photo_id
                                    : Logo
                                }
                                className="img-fluid"
                                alt="image"
                                preview={false}
                              />
                            </div>
                            <div className="nameSection">
                              <h3 className="name">{edittReview?.name}</h3>
                              <Form.Item
                                name="rating"
                                initialValue={edittReview?.rating}
                              >
                                <Rate
                                  key={Key}
                                  defaultValue={edittReview.rating}
                                  name="rating"
                                  onChange={(e) => handleRating(e)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <Form.Item
                          initialValue={edittReview.review}
                          //   defaultValue={edittReview.review}
                          name="review"
                        >
                          <Input.TextArea
                            className="formControl"
                            name="review"
                            rows={10}
                            key={Key}
                            onBlur={(e) => handleReview(e)}
                            defaultValue={edittReview?.review}
                            placeholder="Your review helps others decide on the right pro for them. Please share your experiences with this professional."
                          />
                        </Form.Item>
                        <Form.Item
                          name="name"
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 16px)",
                          }}
                        >
                          <Input
                            className="formControl"
                            placeholder="Name"
                            // name="name"
                            onBlur={(e) => handleReview(e)}
                            key={Key}
                            disabled
                            defaultValue={edittReview?.name}
                          />
                        </Form.Item>
                        <Form.Item
                          name="date"
                          style={{
                          display: 'inline-block',
                          width: 'calc(50% - 0px)',
                          margin: '0 0 0 16px'
                          }}
                          >
                          <DatePicker 
                            // defaultValue={moment()}
                            defaultValue={moment()}
                            format={"MMMM DD, YYYY"}
                            disabled
                            key={Key}
                            className="formControl w-100" 
                            onBlur={(e) => handleReview(e)}
                        />
                        </Form.Item>
                        <Form.Item className="text-center">
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadings[0]}
                            className="submitBtn w-auto"
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReviewForm;
