import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, notification, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BGImg } from '../../constant/images';
import * as forgotPassword from "../../services/forgotPasswordService";
import { useDispatch } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";

const ForgotPassword = () => {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [loadings, setLoadings] = useState([]);
  const [loginInput, setLoginInput] = useState({
    email: '',
  })

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };

  const handleInput = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
  }

  const onFinish = (values) => {
    enterLoading(0);
    const data = {
      email: loginInput.email,
    }
    dispatch(forgotPassword.forgotPassword(data))
      .then((res) => {
        localStorage.setItem('user_email', res.email);
        notification.open({
          message: "Email sent successfully!",
          icon: (
            <CheckCircleOutlined
              style={{
                color: '#d62b47',
              }}
            />
          ),
        });
        navigate('/email-sent')
      })
      .catch((err) => {
        notification.error({
          message: 'This email not registerd.'
        })
        console.info("Get Error Edit State...");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const leftSectionStyle = {
    backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    height: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <div className='authWrapper'>
      <div className='authWrapInner'>
        <div className='container-fluid'>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify='center'>
            <Col xs={24} md={24} xl={16}>
              <Card bordered={false} className="authCard">
                <Row>
                  <Col xs={24} md={12}>
                    <div className='leftSection' style={leftSectionStyle}>
                      <div className='contentSection'>
                        <h3 className='secTitle'>Welcome</h3>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className='rightSection secPadding'>
                      <div className='formWrap'>
                        <div className='formTitle'>
                          <h3 className='frmTitle'>Forgot your Password?</h3>
                          <p className='subTitle'>Enter your email address and we'll send you a link to reset your password.</p>
                        </div>
                        <Form
                          name='registerForm'
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                              },
                              {
                                required: true,
                                message: 'Please input your E-mail!',
                              },
                            ]}
                          >
                            <Input 
                              placeholder='Email Address' 
                              className="formControl" 
                              name="email"
                              onChange={handleInput} 
                              value={loginInput.email}
                            />
                          </Form.Item>
                          
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadings[0]}
                            className="submitBtn"
                          >Continue </Button>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;