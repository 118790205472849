import React, { useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { PageBanner } from '../../components/Banner/pageBanner';
import InboxData from '../../datatables/inboxDatatable';
import InboxDataCustomer from '../../datatables/customerInbox';

const Inbox = () => {
    const auth_role = localStorage.getItem('auth_role');
    const queryParams = new URLSearchParams(window.location.search);
    const user_id = queryParams.get("user_id");

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='inboxWrapper'>
            <div className='inboxInnerWrap'>
                <PageBanner
                    bannerText="Inbox"
                    colorText=""
                />
                <div className='container-fluid'>
                    <div className='contentWrap'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify="center">
                            <Col xs={24} md={18}>
                                <div className='tableWrap'>
                                    <Card
                                        title="Inbox"
                                        bordered={false}
                                        className="tableCard"
                                    >
                                        {auth_role === 'Artist' ?
                                            <InboxData user={user_id} /> :
                                            <InboxDataCustomer user={user_id} />
                                        }
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Inbox;