import React from 'react';
import { BGImg } from '../../constant/images';

const bannerStyle = {
    backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    height: '450px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export const PageBanner = ({ bannerText, bannerSubText, colorText }) => {
    return (
        <div className='bannerWrapper'>
            <div className='innerWrap'>
                <div className='bannerSec' style={bannerStyle}>
                    <div className='bannerContent'>
                        <h3 className='secTitle'>{bannerText} <span className='colorText'>{colorText}</span></h3>
                        <p className='subText'>{bannerSubText}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
