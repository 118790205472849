import React, { useState, useEffect } from "react";
import { Row, Col, Layout, Breadcrumb, Card, Image, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as allSaveDraft from "../../services/getAllFolderServices";
import { useDispatch, useSelector } from "react-redux";
import { AvatarImg } from "../../constant/images";

const { Meta } = Card;

const SavedArtist = () => {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [savedArtistData, setSavedArtistData] = useState([]);

  const userDetails = useSelector((state) => state?.User?.userDetails);

  const getListAllFolder = () => {
    dispatch(allSaveDraft.getAllSaveDraftFolder(userDetails.user_id))
      .then((res) => {
        setLoading(true);

        setSavedArtistData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  useEffect(() => {
    getListAllFolder();
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const OpenFolder = (id) => {
    navigate("/saved-artists/save-artist-folder/" + id);
  };

  let anyItemWithArtistDetails = false;

  savedArtistData &&
    savedArtistData.forEach((item, index) => {
      if (item?.artist_details && item?.artist_details.length > 0) {
        anyItemWithArtistDetails = true;
      }
    });

  return (
    <Layout style={{ padding: "0 1rem", background: "#FFF" }}>
      <Row justify="space-between" align={"center"}>
        <Col md={12}>
          <div className="pageTitle">
            <div className="breadcrumbIcon">
              <UserOutlined />
            </div>
            <h6>Saved Artists</h6>
          </div>
        </Col>
        <Col md={12} className="breadFlex">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"} className="breadcrLink">
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Saved Artists</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {loading ? (
        <div className="spinDiv">
          <Spin size="large" />
        </div>
      ) : (
        ""
      )}
      <Card bordered={false} style={{ marginTop: "1rem" }}>
        <div className="cardInnerBody">
          <Row>
            {savedArtistData &&
              savedArtistData.map((item, index) =>
                item?.artist_details && item?.artist_details?.length > 0 ? (
                  <Col xs={24} md={7} className="cardColumn">
                    <div className="innerCard cardImgWrapper">
                      <Card
                        hoverable
                        onClick={() => OpenFolder(item.folder_id)}
                        key={index}
                        className="savedInnerCard savedCard"
                        bordered
                        cover={
                          <>
                            {item &&
                              item.artist_details &&
                              item.artist_details.map((artistdata, i) => (
                                <div
                                  className={
                                    savedArtistData &&
                                      savedArtistData.length === 4
                                      ? "img-wrapper"
                                      : "saveArtist col-md-6 img-wrapper"
                                  }
                                  key={index}
                                >
                                  <Image
                                    src={
                                      artistdata.artist_image
                                        ? artistdata.artist_image
                                        : AvatarImg
                                    }
                                    className="innerImg"
                                    alt="image"
                                    preview={false}
                                  />
                                  <span>{artistdata?.artist_name}</span>
                                </div>
                              ))}
                          </>
                        }
                      >
                        <Meta title={item.folder_name} className="cardTitle" />
                      </Card>
                    </div>
                  </Col>
                ) : (
                  ""
                )
              )}
            {!anyItemWithArtistDetails && (
              <div className="noArtistWrapper">
                <p>You haven't saved any artist yet.</p>
              </div>
            )}
          </Row>
        </div>
      </Card>
    </Layout>
  );
};

export default SavedArtist;
