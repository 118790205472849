import React from 'react';
import { Row, Col } from "antd";
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { LogoFooter } from '../../constant/images';
import Copywrite from './copywrite';

function Index() {
    return (
        <div className="footer">
            <div className="footerInner">
                <div className="container-fluid">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                        <Col xs={24} md={8} lg={8}>
                            <div className="footeColFirst">
                                <div className="footerColLogo">
                                    <Link className="footer-brand" to={"/"}>
                                        <img src={LogoFooter} className="img-fluid" alt="logo" />
                                    </Link>
                                </div>
                                <div className="footerContent">
                                    <p className="aboutDesc">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <div className='socialIcons'>
                                        <Link to={'/'} className='socialIcn'>
                                            <FaFacebookF />
                                        </Link>
                                        {/* <Link to={'/'} className='socialIcn'>
                                            <FaInstagram />
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={8} lg={8}>
                            <div className="footeColFirst">
                                <div className="footerColTitle">
                                    <h5>Quick Links</h5>
                                </div>
                                <div className="footerContent">
                                    <ul className="footerMenu">
                                        <li className="ftrMenuitems">
                                            <Link
                                                className="ftrMenuLink"
                                                to={"/"}
                                            >
                                                {'>'} About Us
                                            </Link>
                                        </li>
                                        <li className="ftrMenuitems">
                                            <Link
                                                className="ftrMenuLink"
                                                to={"/"}
                                            >
                                                {'>'} Blogs
                                            </Link>
                                        </li>
                                        <li className="ftrMenuitems">
                                            <Link
                                                className="ftrMenuLink"
                                                to={"/contact-us"}
                                            >
                                                {'>'} Contact Us
                                            </Link>
                                        </li>
                                        <li className="ftrMenuitems">
                                            <Link
                                                className="ftrMenuLink"
                                                to={"/"}
                                            >
                                                {'>'} Join as an Artist
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={8} lg={8}>
                            <div className="footeColFirst">
                                <div className="footerColTitle">
                                    <h5>Useful Links</h5>
                                </div>
                                <div className="footerContent">
                                    <ul className="footerMenu">
                                        <li className="ftrMenuitems">
                                            <Link
                                                className="ftrMenuLink"
                                                to={"/faqs"}
                                            >
                                                {'>'} FAQs
                                            </Link>
                                        </li>
                                        <li className="ftrMenuitems">
                                            <Link
                                                className="ftrMenuLink"
                                                to={"/privacy-policy"}
                                            >
                                                {'>'} Privacy Policy
                                            </Link>
                                        </li>
                                        <li className="ftrMenuitems">
                                            <Link
                                                className="ftrMenuLink"
                                                to={"/terms-and-condition"}
                                            >
                                                {'>'} Terms & Conditions
                                            </Link>
                                        </li>
                                        <li className="ftrMenuitems">
                                            <Link
                                                className="ftrMenuLink"
                                                to={"/cookies-policy"}
                                            >
                                                {'>'} Cookies Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Copywrite />
        </div>
    )
}

export default Index;