import { AUTH_USER, VIDEO_UPLOAD, CLEAR_VIDEO_DETAILS } from '../action-type';

export const set_user_auth = (payload) => {
  return {
    type: AUTH_USER,
    payload: payload,
  };
};


export const video_upload = (payload) => {
  return {
    type: VIDEO_UPLOAD,
    payload: payload,
  };
};

export const clearVideoDetails = () => {
  return {
    type: CLEAR_VIDEO_DETAILS,
  };
};