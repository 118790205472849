import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Select, Image, Button, notification } from 'antd';
import { AboutBusinessImg } from '../../constant/images';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as profileService from "../../services/createProfile";
import * as categoryService from "../../services/categoryService";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useLocation } from 'react-router-dom';

const { Option } = Select;

const AboutBusiness = () => {
   let navigate = useNavigate();
   var dispatch = useDispatch();
   const [loadings, setLoadings] = useState([]);
   const userDetails = useSelector((state) => state?.User?.userDetails);
   const [countries, setCountries] = useState([]);
   const [category, setCategory] = useState([]);

   const enterLoading = (index) => {
      setLoadings((prevLoadings) => {
         const newLoadings = [...prevLoadings];
         newLoadings[index] = true;
         return newLoadings;
      });
      setTimeout(() => {
         setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
         });
      }, 6000);
   };

   const fetchCountries = () => {
      dispatch(profileService.getCountries())
         .then((res) => {
            setCountries(Array.from(new Set(res.country_list)));
         })
         .catch((err) => {
            console.info("Get Error Edit State...");
         });
   }

   const fatchCategories = () => {
      dispatch(categoryService.getCategories())
         .then((res) => {
            setCategory(res.data)
         })
         .catch((err) => {
            console.log({ err });
         })
   }

   useEffect(() => {
      fetchCountries();
      fatchCategories();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [profileInput, setProfileInput] = useState({
      artist_name: '',
      category_name: '',
      ph_ext: '',
      phone_number: '',
      country: '',
      first_name: '',
      last_name: '',
   })

   const getLoginInfo = useLocation();
   let exisitngLoginInfo = getLoginInfo?.state?.loginData;

   const handleInput = (input) => (e) => {
      if (input === 'country' || input === 'category_name') {
         setProfileInput({ ...profileInput, [input]: e });
      } else {
         setProfileInput({ ...profileInput, [e.target.name]: e.target.value });
      }

   }

   const onFinish = (e) => {
      enterLoading(0);
      const data = {
         artist_name: profileInput.artist_name,
         category_name: profileInput.category_name,
         ph_ext: profileInput.ph_ext,
         phone_number: profileInput.phone_number,
         country: profileInput.country,
         first_name: profileInput.first_name,
         last_name: profileInput.last_name,
         user_id: exisitngLoginInfo?.user_id ? exisitngLoginInfo?.user_id : userDetails.user_id,
      }
      dispatch(profileService.createProfile(data))
         .then((res) => {
            notification.open({
               message: "Profile successfully created!",
               icon: (
                  <CheckCircleOutlined
                     style={{
                        color: '#d62b47',
                     }}
                  />
               ),
            });

            navigate('/pricing/?user_id=' + userDetails.user_id)
         })
         .catch((err) => {
            console.info("Get Error Edit State...");
         });
   };

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   return (
      <div className='businessWrapper'>
         <div className='businessWrapInner'>
            <div className='container-fluid'>
               <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                  <Col xs={24} md={24} xl={24}>
                     <div className='titleRow'>
                        <h3 className='pageTitle'>Let’s get started by creating your profile</h3>
                        <p>All fields are required!</p>
                     </div>
                  </Col>
               </Row>
               <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify='center' className="my-4">
                  <Col xs={24} md={24} xl={16}>
                     <Card bordered={false} className="authCard">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                           <Col xs={24} md={12}>
                              <div className='leftSection'>
                                 <div className='contentSection'>
                                    <Image src={AboutBusinessImg} preview={false} className="img-fluid" />
                                 </div>
                              </div>
                           </Col>
                           <Col xs={24} md={12}>
                              <div className='rightSection'>
                                 <div className='formWrap'>
                                    <Form
                                       name='businessForm'
                                       onFinish={onFinish}
                                       onFinishFailed={onFinishFailed}
                                       autoComplete="off"
                                       layout="vertical"
                                    >
                                       <Form.Item
                                          name="artist_name"
                                          label="Artist/Studio Name"
                                          rules={[{
                                             required: true,
                                             message: 'Please enter your name!'
                                          }]}
                                       >
                                          <Input
                                             name="artist_name"
                                             placeholder='Artist/Studio Name'
                                             className="formControl"
                                             onChange={handleInput('artist_name')}
                                             value={profileInput.artist_name}
                                          />
                                       </Form.Item>
                                       <Form.Item
                                          name="category_name"
                                          label="Category"
                                          rules={[{
                                             required: true,
                                             message: 'Please select your category!'
                                          }]}
                                       >
                                          <Select
                                             placeholder="Select Category"
                                             className="formControl"
                                             onChange={handleInput('category_name')}
                                             value={profileInput.category_name}
                                             name="category_name"
                                          >
                                             {category?.map((item, index) => {
                                                return (
                                                   <Option value={item} key={index}>{item}</Option>
                                                )
                                             })}
                                          </Select>
                                       </Form.Item>
                                       <Row gutter={16}>
                                          <Col xs={12}>
                                             <Form.Item
                                                label="Phone Number"
                                                className="mb-0"
                                             >
                                                <Input.Group compact className="dFlexCode">
                                                   <Form.Item
                                                      name="ph_ext"
                                                      className="w-30 ph_ext"
                                                      rules={[{
                                                         required: true,
                                                         message: 'Code required',
                                                         pattern: new RegExp("^[0-9, +]*$"),
                                                      }]}
                                                   >
                                                      <Input
                                                         placeholder="+1"
                                                         name="ph_ext"
                                                         onChange={handleInput('ph_ext')}
                                                         value={profileInput.ph_ext}
                                                         className="w-100 frmCntrl"
                                                         maxLength={4}
                                                      />
                                                   </Form.Item>
                                                   <Form.Item
                                                      placeholder="Phone Number"
                                                      name="phone_number"
                                                      rules={[{
                                                         required: true,
                                                         message: 'Please enter your phone number!',
                                                         pattern: new RegExp("^[0-9, +]*$"),
                                                      },
                                                      ]}
                                                      className="w-70"
                                                   >
                                                      <Input
                                                         className="w-100 fmCntrl"
                                                         name="phone_number"
                                                         onChange={handleInput('phone_number')}
                                                         value={profileInput.phone_number}
                                                         maxLength={12}
                                                         minLength={5}
                                                      />
                                                   </Form.Item>

                                                </Input.Group>
                                             </Form.Item>
                                          </Col>
                                          <Col xs={12}>
                                             <Form.Item
                                                name="country"
                                                label="Country"
                                                rules={[{
                                                   required: true,
                                                   message: 'Please select your country!'
                                                }]}
                                             >
                                                <Select
                                                   showSearch
                                                   placeholder="Select Country"
                                                   className="formControl customSelectCountry"
                                                   onChange={handleInput('country')}
                                                   value={profileInput.country}
                                                   name="country"
                                                   optionFilterProp="children"
                                                   filterOption={(input, option) =>
                                                      option.children.toLowerCase().includes(input.toLowerCase())
                                                   }
                                                   filterSort={(optionA, optionB) =>
                                                      optionA.children
                                                         .toLowerCase()
                                                         .localeCompare(
                                                            optionB.children.toLowerCase()
                                                         )
                                                   }
                                                >
                                                   {countries?.map((country) => {
                                                      return (
                                                         <Option value={country} key={country}>{country}</Option>
                                                      )
                                                   })}
                                                </Select>
                                             </Form.Item>
                                          </Col>
                                       </Row>
                                       <Form.Item
                                          name="first_name"
                                          label="Contact Name"
                                          style={{
                                             display: 'inline-block',
                                             width: 'calc(50% - 16px)',
                                          }}
                                          rules={[
                                             {
                                                required: true,
                                                message: 'Please enter first name',
                                             }
                                          ]}
                                       >
                                          <Input
                                             name="first_name"
                                             placeholder='First Name'
                                             className="formControl"
                                             onChange={handleInput('first_name')}
                                             value={profileInput.first_name}
                                          />
                                       </Form.Item>
                                       <Form.Item
                                          name="last_name"
                                          label=' '
                                          style={{
                                             display: 'inline-block',
                                             width: 'calc(50% - 0px)',
                                             margin: '0 0 0 16px'
                                          }}
                                          rules={[
                                             {
                                                message: 'Please enter last name',
                                             }
                                          ]}
                                       >
                                          <Input
                                             name="last_name"
                                             placeholder='Last Name'
                                             className="formControl"
                                             onChange={handleInput('last_name')}
                                             value={profileInput.last_name}
                                          />
                                       </Form.Item>
                                       <Form.Item
                                          className='text-end'
                                       >
                                          <Button
                                             type="primary"
                                             htmlType="submit"
                                             loading={loadings[0]}
                                             className="submitBtn"
                                          >Next </Button>
                                       </Form.Item>
                                    </Form>
                                 </div>
                              </div>
                           </Col>
                        </Row>
                     </Card>
                  </Col>
               </Row>
            </div>
         </div>
      </div>
   );
}

export default AboutBusiness;