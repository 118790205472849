
const initialState = {
  userDetails: {},
  videoDetails:{}
};

const State = (state = initialState, { type, payload = null }) => {
  switch (type) {
 
    case 'AUTH_USER':
      return setUserDetails(state, payload);
    case 'VIDEO_UPLOAD':
    return setVideoDetails(state, payload);
    case 'CLEAR_VIDEO_DETAILS':
      return clearVideoDetails(state);
    default:
      return state;
  }
};

function setUserDetails(state, payload) {

  return {
    ...state,
    userDetails: payload.data,
  };
}

function setVideoDetails(state, payload) {

  return {
    ...state,
    videoDetails: payload,
  };
}

function clearVideoDetails(state) {
  return {
    ...state,
    videoDetails: {},
  };
}

export const getAuth = (state) => state.auth.isLoggedIn;

export default State;
