import React from 'react';
import { Form, Input, Tooltip, Row, Col, Select } from 'antd';
import { QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const StepFour = (props) => {
    console.log("currency", props)

    return (
        <div className='stepWrapper'>
            <div className='stepwrapInner'>
                <div className='stepTitle'>
                    <h4>How do you charge?</h4>
                    <p>Provide details on how clients are charged.</p>
                </div>  
                <Form.Item
                    label={
                        <>
                            <span className='me-2'>Typical Hourly Rate</span>
                            <Tooltip
                                title={
                                    <>
                                        <p>White should I provide this?</p>
                                        <span className='small'>While this field is optional, as we understand how difficult it is to esimate considerations of potential clients. It is recommended that you provide a rough range of your hourly rate in order for suitable clients to find you.</span>
                                    </>
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </>
                    }
                >
                    <Input.Group size="large">
                        <Row gutter={16}>
                            <Col xs={6} sm={24} md={4}>
                                <Form.Item
                                    name='currency'
                                >
                                    <Select
                                        showSearch
                                        name='currency'
                                        className="formControl"
                                        onChange={props.handleInput('currency')}
                                        defaultValue={props.profileInput.currency}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children
                                            .toLowerCase()
                                            .localeCompare(
                                                optionB.children.toLowerCase()
                                            )
                                        }
                                    >
                                        {props.country?.map((country) => {
                                            return (
                                                <Option value={country.currency} key={country.isoCode}>{country.currency}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={18} sm={24} md={8}>
                                <Input.Group compact className='mobUI'>
                                    <Form.Item 
                                        name='from_price'
                                        rules={[{
                                            required: true,
                                            message: 'Please input price'
                                        }]}
                                    >
                                        <Input
                                            name='from_price'
                                            placeholder="From"
                                            className='frmCntrl'
                                            onChange={props.handleInput('from_price')}
                                            defaultValue={props.profileInput.from_price}
                                        />
                                    </Form.Item>
                                    <Form.Item 
                                        name='to_price'
                                        rules={[{
                                            required: true,
                                            message: 'Please input price'
                                        }]}
                                    >
                                        <Input
                                            name='to_price'
                                            placeholder="To"
                                            className='fmCntrl'
                                            onChange={props.handleInput('to_price')}
                                            defaultValue={props.profileInput.to_price}
                                        />
                                    </Form.Item>
                                </Input.Group>
                            </Col>
                        </Row>
                    </Input.Group>
                </Form.Item>
                <Form.Item
                    name='additional_details'
                    label={
                        <>
                            <span className='me-2'>Additional Details</span>
                            <Tooltip
                                title={
                                    <>
                                        <p className='mb-0'>Provide additonal details on cost quotation considerations, e.g.</p>
                                        <span className='small'>- Size</span><br />
                                        <span className='small'>- Placement</span><br />
                                        <span className='small'>- Level of detail</span><br />
                                        <span className='small'>- Cover-up</span><br />
                                        <span className='small'>etc.</span>
                                    </>
                                }
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </>
                    }
                >
                    <Input.TextArea
                        name='additional_details'
                        className='formControl'
                        placeholder='Additioanl Details'
                        rows={7}
                        onChange={props.handleInput('additional_details')}
                        defaultValue={props.profileInput.additional_details}
                    />
                </Form.Item>
            </div>
        </div>
    );
}

export default StepFour;