import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Layout,
  Breadcrumb,
  Card,
  Image,
  Avatar,
  Menu,
  Spin,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "../../components/Buttons/buttons";
import { AvatarImg } from "../../constant/images";
import * as allPastDraft from "../../services/getAllFolderServices";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
const { Sider } = Layout;

const PastArtistFolder = () => {
  let navigate = useNavigate();
  const userDetails = useSelector((state) => state?.User?.userDetails);
  const [current, setCurrent] = useState("past-artists");
  const onClick = (e) => {
    setCurrent(e.key);
    navigate("/" + e.key);
  };

  var dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [pastArtistData, setPastArtistData] = useState([]);

  const getListAllFolder = () => {
    setLoader(true);
    setOpen(true);
    dispatch(allPastDraft.getAllPastArtist(userDetails.user_id))
      .then((res) => {
        setPastArtistData(res.data);
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getListAllFolder();
  }, []);

  const writeReview = (id) => {
    navigate("/write-review/?user_id=" + id);
  };
  const editReview = (id) => {
    navigate("/edit-review/?user_id=" + id);
  };

  return (
    <div className="clientDashWrapper ">
      <div className="innerWrap">
        <Layout
          style={{
            padding: "2.5rem 1rem",
            background: "#FFF",
            minHeight: "70vh",
          }}
          className="PastArtist"
        >
          <Sider
            className="dashSidebar Layout-mobile"
            breakpoint="lg"
            collapsedWidth="0"
            width={300}
            style={{
              background: "#d62b47",
              borderRadius: "10px",
            }}
          >
            <div className="custAvatar d-flex">
              <Avatar className="me-2" size={"large"} src={AvatarImg} />
              <div className="userData">
                <h6>{userDetails?.name}</h6>
                <p>User</p>
              </div>
            </div>
            <Menu
              selectedKeys={[current]}
              onClick={onClick}
              mode="inline"
              items={[
                {
                  key: "saved-artists",
                  icon: <UserOutlined />,
                  label: "Saved Artists",
                },
                {
                  key: "past-artists",
                  icon: <UserOutlined />,
                  label: "Past Artists",
                },
                {
                  key: "3",
                  icon: "",
                  label: "",
                },
              ]}
            />
          </Sider>
          <Layout style={{ padding: "0 1rem", background: "#FFF" }}>
            <Row justify="space-between">
              <Col md={12}>
                <div className="pageTitle">
                  <div className="breadcrumbIcon">
                    <UserOutlined />
                  </div>
                  <h6>Past Artists</h6>
                </div>
              </Col>
              <Col md={12} className="breadFlex">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to={"/"} className="breadcrLink">
                      Home
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Past Artists</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            {loader === true ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <Spin size="large" />
              </Backdrop>
            ) : (
              <Card
                bordered={false}
                className="savedCard"
                style={{ marginTop: "1rem" }}
              >
                <div className="cardInnerBody">
                  <Row gutter={16}>
                    {pastArtistData.map((item, index) => (
                      <Col xs={24} md={6} className="artistContainer">
                        <div className="liistWrapper customListWrapper">
                          <Card
                            bordered
                            cover={
                              <Image
                                src={
                                  item?.user_profile?.profile_image !== null
                                    ? item?.user_profile?.profile_image
                                        ?.media_url
                                    : AvatarImg
                                }
                                alt="example"
                                preview={false}
                                className="img-fluid"
                              />
                            }
                            actions={[
                              <IconButton
                                type="primary"
                                handleClick={() => console.log()}
                                text={item.name}
                                className=" artistName w-auto me-0 mb-3"
                                key="message"
                              />,
                              <>
                                {item?.review === false ? (
                                  <IconButton
                                    type="primary"
                                    handleClick={() => writeReview(item.id)}
                                    text="Write a Review"
                                    className="w-auto reviewBtn"
                                    key="profile"
                                  />
                                ) : (
                                  <IconButton
                                    type="primary"
                                    handleClick={() => editReview(item.id)}
                                    text="View/Edit Review"
                                    className="w-auto reviewBtn"
                                    key="profile"
                                  />
                                )}
                              </>,
                            ]}
                          ></Card>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Card>
            )}
          </Layout>
        </Layout>
      </div>
    </div>
  );
};

export default PastArtistFolder;
