import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Modal, Image, notification, Button, message } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons/buttons";
import { UserImg, TattooMachine, BGImg } from "../../constant/images";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import * as RegisterServices from "../../services/registerCustomerService";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions/user";
import { TATTOO_URL } from "../../Helper";

const Register = () => {
    var dispatch = useDispatch();
    let navigate = useNavigate();
    const [form] = Form.useForm();
    // const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewModal, setNewModal] = useState(false);
    const [loadings, setLoadings] = useState([]);
    const queryParams = new URLSearchParams(window.location.search);
    const role = queryParams.get("role");
    const userDetails = useSelector((state) => state?.User?.userDetails);
    const [messageApi, contextHolder] = message.useMessage();
    const [loginUrl, setLoginUrl] = useState(null);
    const [googleUrl, setGoogleUrl] = useState(null);
    const user = useSelector((state) => state?.User?.user);

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 5000);
    };

    useEffect(() => {
        fetch(TATTOO_URL + "api/auth/facebook", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong!");
            })
            .then((data) => setLoginUrl(data.url))
            .catch((error) => console.error(error));

        fetch(TATTOO_URL + "api/auth/google", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong!");
            })
            .then((data) => setGoogleUrl(data.url))
            .catch((error) => console.error(error));

    }, []);

    const showModal = async () => {
        try {
            await form.validateFields(
                [
                    'email',
                    'password',
                ]
            );
            setIsModalOpen(true);
        } catch {
            messageApi.open({
                type: 'error',
                content: 'Please enter email and password to procced',
            });
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showNewModal = () => {
        setNewModal(true);
    };

    const handleOkNew = () => {
        setNewModal(false);
    };

    const handleCancelNew = () => {
        setNewModal(false);
    };

    const [registerInput, setRegisterInput] = useState({
        name: "",
        email: "",
        password: "",
        c_password: "",
        firstname: "",
        lastname: "",
        location: "",
    });

    const getLoginInfo = useLocation();
    let exisitngLoginInfo = getLoginInfo?.state?.loginData;

    useEffect(() => {
        showNewModal(true);
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleInput = (e) => {
        setRegisterInput({ ...registerInput, [e.target.name]: e.target.value });
    };

    const handleNavigate = () => {
        navigate("/login");
    };
    const onFinish = async (e) => {
        e.preventDefault();
        try {
            await form.validateFields(
                [
                    'firstname',
                    'lastname',
                    'email',
                    'password',
                ]
            );
            enterLoading(0);
            const queryParams = new URLSearchParams(window.location.search);
            const role = queryParams.get("role");
            const data = {
                name: exisitngLoginInfo?.name ? exisitngLoginInfo?.name : registerInput.firstname,
                email: exisitngLoginInfo?.email ? exisitngLoginInfo?.email : registerInput.email,
                password: registerInput.password,
                c_password: registerInput.password,
                firstname: exisitngLoginInfo?.name ? exisitngLoginInfo?.name : registerInput.firstname,
                lastname: exisitngLoginInfo?.name ? exisitngLoginInfo?.name : registerInput.lastname,
                role: role ? role : 'Customer',
            };
            dispatch(RegisterServices.register(data))
                .then((res) => {
                    // return;
                    if (res.success === true) {
                        localStorage.setItem("auth_token", res.data.token);
                        localStorage.setItem("auth_role", role ? role : "Customer");
                        dispatch(Actions.set_user_auth(res));
                        if (role === "Artist") {
                            navigate("/create-profile")

                        } else {
                            navigate("/");
                        }
                    } else {
                        console.log('error');
                    }
                })
                .catch((err) => {
                    notification.error({
                        message: 'The email has already been taken.'
                    })
                });
        } catch {
            console.log('Validation error')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleCustomer = () => {
        setNewModal(false);
        navigate('/register/?role=Customer');
        localStorage.setItem("Role", 'Customer')
    }

    const handleArtist = () => {
        setNewModal(false);
        navigate('/register?role=Artist');
        localStorage.setItem("Role", 'Artist')
    }
    let key = Math.floor(Math.random() * 1000);


    const leftSectionStyle = {
        backgroundImage: `url(${BGImg})`,
        backgroundSize: "cover",
        height: "100%",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div className="authWrapper">
            {contextHolder}
            <div className="authWrapInner">
                <div className="container-fluid">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify="center">
                        <Col xs={24} md={24} xl={16}>
                            <Card bordered={false} className="authCard">
                                <Row>
                                    <Col xs={24} md={12}>
                                        <div className="leftSection" style={leftSectionStyle}>
                                            <div className="contentSection">
                                                <h3 className="secTitle">Welcome</h3>
                                                <p className="subText">Already have an account?</p>
                                                <PrimaryButton
                                                    type="primary"
                                                    handleClick={() => handleNavigate()}
                                                    text="Sign In"
                                                    className="w-120 me-0"
                                                />
                                                <div className="switchBtnSec">
                                                    <button
                                                        type="secondary"
                                                        className="switchBtn"
                                                        onClick={setNewModal}
                                                    >
                                                        Switch Profile
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <div className="rightSection">
                                            <div className="formWrap">
                                                <div className="formTitle">
                                                    {role === 'Artist' ? (
                                                        <h3>Join Now as Artist</h3>
                                                    ) : (
                                                        <h3>Join as Client</h3>
                                                    )}
                                                </div>
                                                <Form
                                                    name="registerForm"
                                                    onFinishFailed={onFinishFailed}
                                                    autoComplete="off"
                                                    layout="vertical"
                                                    form={form}
                                                >
                                                    {role === 'Artist' ?
                                                        <Row gutter={16}>
                                                            <Col xs={24} md={12}>
                                                                <Form.Item
                                                                    name="firstname"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter first name',
                                                                        }
                                                                    ]}
                                                                    key={key}
                                                                    initialValue={exisitngLoginInfo?.name ? exisitngLoginInfo.name : ''}
                                                                >
                                                                    <Input
                                                                        name="firstname"
                                                                        placeholder="First Name"
                                                                        className="formControl"
                                                                        onBlur={handleInput}
                                                                        value={registerInput.firstname}
                                                                        defaultValue={exisitngLoginInfo?.name ? exisitngLoginInfo.name : ''}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} md={12}>
                                                                <Form.Item
                                                                    name="lastname"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter last name',
                                                                        }
                                                                    ]}
                                                                    key={key}
                                                                    initialValue={exisitngLoginInfo?.name ? exisitngLoginInfo.name : ''}
                                                                >
                                                                    <Input
                                                                        name="lastname"
                                                                        placeholder="Last Name"
                                                                        className="formControl"
                                                                        onBlur={handleInput}
                                                                        value={registerInput.lastname}
                                                                        defaultValue={exisitngLoginInfo?.name ? exisitngLoginInfo.name : ''}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row> : ''
                                                    }
                                                    <Form.Item
                                                        name="email"
                                                        rules={[
                                                            {
                                                                type: 'email',
                                                                message: 'Please enter a valid email!',
                                                            },
                                                            {
                                                                required: true,
                                                                message: 'Email is required',
                                                            },
                                                        ]}
                                                        key={key}
                                                        initialValue={exisitngLoginInfo?.email ? exisitngLoginInfo.email : ''}
                                                    >
                                                        <Input
                                                            name="email"
                                                            onBlur={handleInput}
                                                            value={registerInput.email}
                                                            placeholder="Email"
                                                            className="formControl"
                                                            defaultValue={exisitngLoginInfo?.email ? exisitngLoginInfo.email : ''}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="password"
                                                        rules={[
                                                            {
                                                                type: 'password',
                                                                message: 'The input is not valid Password!',
                                                            },
                                                            {
                                                                required: true,
                                                                message: 'Password is required',
                                                            },
                                                            {
                                                                message: 'Password must contain a minimum of 8 characters (including 1 symbol and 1 number)',
                                                                pattern: new RegExp(/^(?=.*[a-z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)
                                                            }
                                                        ]}
                                                    >
                                                        <Input.Password
                                                            name="password"
                                                            onBlur={handleInput}
                                                            value={registerInput.password}
                                                            placeholder="Create a password"
                                                            className="formControl"
                                                        />
                                                    </Form.Item>
                                                    {role === 'Artist' ?
                                                        <Button
                                                            type="primary"
                                                            onClick={(e) => onFinish(e)}
                                                            loading={loadings[0]}
                                                            className="submitBtn"
                                                        >Sign Up </Button> :
                                                        <Button
                                                            type="primary"
                                                            onClick={() => showModal()}
                                                            loading={loadings[0]}
                                                            className="submitBtn"
                                                        >Sign Up </Button>
                                                    }
                                                    <Modal
                                                        title="Tell us a little about you"
                                                        open={isModalOpen}
                                                        onOk={handleOk}
                                                        onCancel={handleCancel}
                                                        footer={false}
                                                        className="formModal"
                                                    >
                                                        <div className="modalBody">
                                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                                                                <Col xs={24} md={24}>
                                                                    <Form.Item
                                                                        name="firstname"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please enter first name',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            name="firstname"
                                                                            placeholder="First Name"
                                                                            className="formControl"
                                                                            onChange={handleInput}
                                                                            value={registerInput.firstname}
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        name="lastname"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please enter last name',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            name="lastname"
                                                                            placeholder="Last Name"
                                                                            className="formControl"
                                                                            onChange={handleInput}
                                                                            value={registerInput.lastname}
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item name="email">
                                                                        <Input
                                                                            name="email"
                                                                            className="formControl"
                                                                            onChange={handleInput}
                                                                            value={registerInput.email}
                                                                            disabled
                                                                        />
                                                                    </Form.Item>
                                                                    {/* <Form.Item name="location">
                                                                        <Input
                                                                            name="location"
                                                                            placeholder="Address"
                                                                            className="formControl"
                                                                            onChange={handleInput}
                                                                            value={registerInput.location}
                                                                        />
                                                                    </Form.Item> */}
                                                                    <Form.Item className="text-center">
                                                                        <Button
                                                                            type="primary"
                                                                            onClick={(e) => onFinish(e)}
                                                                            loading={loadings[0]}
                                                                            className="submitBtn w-auto"
                                                                        >Done</Button>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Modal>
                                                </Form>
                                                <div className="orSection">
                                                    <p>or sign up with</p>
                                                </div>
                                                <div className="socialLoginBtn">
                                                    {/* <Link to={"/register"} className="sclLogBtn">
                                                        <FcGoogle />
                                                    </Link>
                                                    <Link to={"/register"} className="sclLogBtn fbIcn">
                                                        <FaFacebookF />
                                                    </Link> */}
                                                    {loginUrl && (
                                                        <a href={loginUrl} className="sclLogBtn">
                                                            {/* <img
                                                            src={<FaFacebookF />}
                                                            alt="FacebookIcon"
                                                            href={loginUrl}
                                                            className="customIcon"
                                                        /> */}
                                                            <FaFacebookF className="customIcon" />
                                                        </a>
                                                    )}
                                                    {googleUrl && (
                                                        <a href={googleUrl} className="sclLogBtn fbIcn">
                                                            <FcGoogle className="customIcon" />
                                                            {/* <img
                                                            src={}
                                                            alt="googleIcon"
                                                            className="customIcon"
                                                        /> */}
                                                        </a>
                                                    )}
                                                </div>
                                                <div className="privacyLinkRow">
                                                    <p>
                                                        By signing up, signing in, or continuing, I agree to
                                                        the Website's <Link to={"/terms-and-condition"}>Terms of Use</Link> and{" "}
                                                        <Link to={"/privacy-policy"}>Privacy Policy</Link>.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {userDetails?.token ? '' :
                <Modal
                    title="Join as"
                    open={isNewModal}
                    onOk={handleOkNew}
                    onCancel={handleCancelNew}
                    footer={false}
                    className="formModal"
                >
                    <div className='modalBody'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                            <Col xs={12} md={12}>
                                <div className='roleButton'>
                                    <div className='roleIcon'>
                                        <Link onClick={() => handleCustomer()} to={'/register/?role=Customer'} className="roleLink">
                                            <Image className="img-fluid" preview={false} alt="role icon" src={UserImg} />
                                        </Link>
                                    </div>
                                    <h4 className='roleTitle'>Client</h4>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                                </div>
                            </Col>
                            <Col xs={12} md={12}>
                                <div className='roleButton'>
                                    <div className='roleIcon'>
                                        <Link onClick={() => handleArtist()} className="roleLink" to={'/register/?role=Artist'}>
                                            <Image className="img-fluid" preview={false} alt="role icon" src={TattooMachine} />
                                        </Link>
                                    </div>
                                    <h4 className='roleTitle'>Artist</h4>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }
        </div>
    );
};

export default Register;