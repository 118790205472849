import React from "react";
import { Row, Col } from "antd";
import { Link } from 'react-router-dom';

const Copywrite = () => {
  return (
    <div className="copywriteSection">
      <div className="container-fluid">
        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 24}}>
          <Col md={12}>
            <p>Copyright © {(new Date().getFullYear())} All Rights Reserved.</p>
          </Col>
          <Col md={12} className="text-end">
            <p>Designed by <Link to={'//www.aleaitsolutions.com/'} className="devLink" target="_blank">ALEAIT SOLUTIONS PVT LTD</Link></p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Copywrite;
