import React from "react";
import { Form, Input, Select, Checkbox } from 'antd';
// import { Link } from 'react-router-dom';
import { IoSearch } from "react-icons/io5";

const { Option } = Select;

const StepTwo = (props) => {
   console.log("cityPorps", props)
   return (
      <div className='stepWrapper'>
         <div className='stepwrapInner'>
            <div className='stepTitle'>
               <h4>Where do you work?</h4>
               <p>Please select the areas where you work so we can match the right clients to you.</p>
            </div>
            <Form.Item
               name='select_area_ids'
               rules={[{
                  required: true,
                  message: 'Please select cities'
               }]}
            >
               <Select
                  mode="multiple"
                  allowClear
                  style={{
                     width: '100%',
                  }}
                  name='select_area_ids'
                  placeholder="Please select cities"
                  onChange={props.handleInput("select_area_ids")}
                  // defaultValue={props.profileInput.select_area_ids}
                  initialValue={props.profileInput.select_area_ids}
               >
                  {props.cities?.map((city, index) => {
                     return (
                        <Option value={city.name} key={index}>{city.name}</Option>
                     )
                  })}
               </Select>
            </Form.Item>
            <div className='stepTitle mt-5'>
               <h4>What type of tattoos do you provide?</h4>
               <p>Select all applicable types.</p>
            </div>
            {props.tags.length > 0 ?
               <>
                  <Input className='formControl mb-4 displayHide' placeholder='Search for services' prefix={<IoSearch />} />
                  <Form.Item
                     name='tattoo_service_ids'
                     rules={[{
                        required: true,
                        message: 'Please select services'
                     }]}
                  >

                     <Checkbox.Group
                        name='tattoo_service_ids'
                        options={props.tags}
                        onChange={props.handleInput('tattoo_service_ids')}
                        defaultValue={props.profileInput.tattoo_service_ids}
                     />
                  </Form.Item>
               </> : ''
            }
            {/* <p>
                  <Link className='link' to={'/'}>See All Services...</Link>
               </p> */}
         </div>
      </div>
   );
}

export default StepTwo;