import React from 'react';
import { Row, Col, Card } from 'antd';
import { Link } from 'react-router-dom';
import { BGImg } from '../../constant/images'
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";

const ForgotPassword = () => {
  const email = localStorage.getItem("user_email");

  const leftSectionStyle = {
    backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    height: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <div className='authWrapper'>
      <div className='authWrapInner'>
        <div className='container-fluid'>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify='center'>
            <Col xs={24} md={24} xl={16}>
              <Card bordered={false} className="authCard">
                <Row>
                  <Col xs={24} md={12}>
                    <div className='leftSection' style={leftSectionStyle}>
                      <div className='contentSection'>
                        <h3 className='secTitle'>Welcome</h3>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className='rightSection secPadding'>
                      <div className='formWrap'>
                        <div className='formTitle'>
                          <h3 className='frmTitle'>Email Sent!</h3>
                          <p className='subTitle'>Check your inbox for an email from Finding Ink with a password reset link (sent to 
                            <strong> {email}</strong>).
                          </p>
                        </div>
                        <div className='orSection'>
                          <p>or login with</p>
                        </div>
                        <div className="socialLoginBtn">
                          <Link to={'/'} className="sclLogBtn">
                            <FcGoogle />
                          </Link>
                          <Link to={'/'} className="sclLogBtn fbIcn">
                            <FaFacebookF />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;