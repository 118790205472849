import Http from "../Http";

export function getCertificates() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.get('api/certificates')
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}