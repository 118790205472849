import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Form, Input, Image, Rate, Spin, DatePicker, Button, notification } from 'antd';
import { Logo } from '../../constant/images';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as profileService from "../../services/createProfile";
import * as reviewService from "../../services/writeReview";
import moment from "moment";

const ReviewForm = () => {
    let navigate = useNavigate();
    var dispatch = useDispatch();
    const [artistData, setArtistData] = useState();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState([]);
    const userDetails = useSelector((state) => state?.User?.userDetails);
    const queryParams = new URLSearchParams(window.location.search);
    const userID = queryParams.get("user_id");
    // const artistID = queryParams.get("id");
    const [reviewData,SetReviewData] = useState([])
    const [reviewInput, setReviewInput] = useState({
        name: "",
        review: "",
        rating: "",
        date: moment().format('MMMM DD, YYYY'),
    });

    let customerId = localStorage.getItem("customer_id");
    let NewCustId = parseInt(customerId);

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 8000);
    };

    const handleInput = (e, name, type) => {
        if(name === 'date') {
            if(type === 'date') {
                let date = moment(e).format('MMMM DD, YYYY');
                setReviewInput({ ...reviewInput, [name]: date });
            }else {
                let value = e.target.value;
                setReviewInput({ ...reviewInput, [name]: value });
              }
        }else if(name === 'rating'){
            let value = e;
            setReviewInput({ ...reviewInput, [name]: value});
        }else {
            setReviewInput({ ...reviewInput, [e.target.name]: e.target.value });
        }
    };

    const fetchArtistData = (userID) => {
        dispatch(profileService.getArtistData(userID))
            .then((res) => {
                setLoading(true);
                setArtistData(res.data);
                setLoading(false);
                SetReviewData(res?.data?.user_profile?.review)
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    };

    useEffect(() => {
    if(reviewData != null){
    dispatch(reviewService.editwriteReview(userID))
    .then((res) => {
    })
    }                   
    },[])
    useEffect(() => {
        fetchArtistData(userID);
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFinish = () => {
        enterLoading(0);
        const data = {
            name: userDetails?.name,
            review: reviewInput?.review,
            rating: reviewInput?.rating,
            date: reviewInput?.date,
            user_id: NewCustId,
            user_profile_id: artistData?.id
        }
        dispatch(reviewService.writeReview(data))
        .then((res) => {
            notification.success({
                message: 'Review submitted',
            })
            //return
            navigate('/user-profile/?user_id=' + userID)
        })
        .catch((err) => {
            console.info("Get Error Edit State...");
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='wrireReviewWrapper'>
            {loading ? (
                <div className='spinDiv'>
                    <Spin size='large' />
                </div>
            ) : (
                <div className='innerWrap'>
                    <div className='container-fluid'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                            <Col xs={24} md={24} xl={24}>
                                <div className='titleRow'>
                                    <h3 className='pageTitle'>Write your review here</h3>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify="center">
                            <Col xs={24} md={16} xl={16}>
                                <div className='reviewFormWrap'>
                                    <Card bordered={false} className="reviewForm">
                                        <div className='cardBody'>
                                            <Form
                                                name='reviewForm'
                                                onFinish={onFinish}
                                                onFinishFailed={onFinishFailed}
                                                autoComplete="off"
                                            >
                                                <div className='topWrap'>
                                                    <div className='profileWrap'>
                                                        <div className='imgSection'>
                                                            <Image src={artistData?.user_profile?.profile_photo_id ? artistData?.user_profile?.profile_photo_id : Logo} className="img-fluid" alt='image' preview={false} />
                                                        </div>
                                                        <div className='nameSection'>
                                                            <h3 className='name'>{artistData?.user_profile?.artist_name}</h3>
                                                            <Form.Item 
                                                                name='rating'
                                                                rules={
                                                                    [
                                                                        {
                                                                            required: true,
                                                                            message: 'Please select rates.',
                                                                        },
                                                                    ]
                                                                }
                                                            >
                                                                <Rate 
                                                                    name='rating'
                                                                    onChange={(e) => {
                                                                        handleInput(
                                                                            e, 'rating', 'rating'
                                                                        )
                                                                    }} 
                                                                    value={reviewInput.rating} 
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Form.Item
                                                    name="review"
                                                    rules={
                                                        [
                                                            {
                                                                required: true,
                                                                message: 'Please enter your messgae here.',
                                                            },
                                                        ]
                                                    }
                                                >
                                                    <Input.TextArea
                                                        className='formControl'
                                                        name="review"
                                                        rows={10}
                                                        onChange={handleInput}
                                                        value={reviewInput.review}
                                                        placeholder="Your review helps others decide on the right pro for them. Please share your experiences with this professional."
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="name"
                                                    style={{
                                                        display: 'inline-block',
                                                        width: 'calc(50% - 16px)',
                                                    }}
                                                >
                                                    <Input
                                                        className='formControl'
                                                        placeholder="Name"
                                                        name="name"
                                                        onChange={handleInput}
                                                        value={reviewInput.name}
                                                        defaultValue={userDetails?.name}
                                                        disabled
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="date"
                                                    style={{
                                                        display: 'inline-block',
                                                        width: 'calc(50% - 0px)',
                                                        margin: '0 0 0 16px'
                                                    }}
                                                >
                                                    <DatePicker 
                                                        defaultValue={moment()}
                                                        format={"MMMM DD, YYYY"}
                                                        disabled
                                                        name="date" 
                                                        className="formControl w-100" 
                                                        onChange={(e) => {
                                                            handleInput(
                                                                e, 'date', 'date'
                                                            )
                                                        }} 
                                                        value={reviewInput.date}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="button" className='text-center'>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        loading={loadings[0]}
                                                        className="submitBtn w-auto"
                                                    >Submit</Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReviewForm;