import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Layout,
  Menu,
  Avatar,
  Breadcrumb,
  Card,
  Image,
  Spin,
} from "antd";
import { AvatarImg } from "../../constant/images";
import { UserOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { IconButton } from "../../components/Buttons/buttons";
import * as allSaveDraft from "../../services/getAllFolderServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;

const ArtistFolder = () => {
  let navigate = useNavigate();
  const userDetails = useSelector((state) => state?.User?.userDetails);
  const [current, setCurrent] = useState("saved-artists");
  const [loading, setLoading] = useState(true);
  const [savedArtistData, setSavedArtistData] = useState([]);
  const params = useParams();
  var dispatch = useDispatch();

  let folder_id = params.id;

  const onClick = (e) => {
    setCurrent(e.key);
    navigate("/" + e.key);
  };

  const getListAllFolder = () => {

    dispatch(allSaveDraft.getSingleFolder(userDetails.user_id, folder_id))
      .then((res) => {
        setLoading(true);
        setSavedArtistData(res.data);
        setLoading(false);
        filterData();
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };
  useEffect(() => {
    if (savedArtistData.length === 0) {
      getListAllFolder();
    }
  });

  const filterData = () => {
    let artistFolder;
    savedArtistData.data.forEach((item, i) => {
      artistFolder.push(item.folder_id);
      return artistFolder;
    });
  };

  const OpenContact = (id) => {
    navigate("/user-profile/?user_id=" + id, { state: { contact: true } });
  };

  const OpenProfile = (id) => {
    navigate("/user-profile/?user_id=" + id);
  };

  return (
    <div className="clientDashWrapper">
      <div className="innerWrap">
        <Layout
          style={{
            padding: "2.5rem 1rem",
            background: "#FFF",
            minHeight: "70vh",
          }}
        >
          <Sider
            className="dashSidebar Layout-mobile"
            breakpoint="lg"
            collapsedWidth="0"
            width={300}
            style={{
              background: "#d62b47",
              borderRadius: "10px",
            }}
          >
            <div className="custAvatar d-flex">
              <Avatar className="me-2" size={"large"} src={AvatarImg} />
              <div className="userData">
                <h6>{userDetails?.name}</h6>
                <p>User</p>
              </div>
            </div>
            <Menu
              selectedKeys={[current]}
              onClick={onClick}
              mode="inline"
              items={[
                {
                  key: "saved-artists",
                  icon: <UserOutlined />,
                  label: "Saved Artists",
                },
                {
                  key: "past-artists",
                  icon: <UserOutlined />,
                  label: "Past Artists",
                },
                {
                  key: "3",
                  icon: " ",
                  label: "",
                },
              ]}
            />
          </Sider>
          <Layout style={{ padding: "0 1rem", background: "#FFF" }}>
            <Row justify="space-between" align={"center"}>
              <Col md={12}>
                <div className="pageTitle">
                  <div className="breadcrumbIcon">
                    <UserOutlined />
                  </div>
                  <h6>Saved Artists Folder</h6>
                </div>
              </Col>
              <Col md={12} className="breadFlex">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to={"/saved-artists"}>Saved Artists</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Saved Artist's Folder</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            {loading ? (
              <div className="spinDiv">
                <Spin size="large" />
              </div>
            ) : (
              <Card bordered={false} className="savedArtistCard">
                <div className="cardInnerBody">
                  <Row gutter={16}>
                    {savedArtistData?.artist_details?.map((item, index) => (
                      <Col xs={24} md={8} className="artistCardColumn">
                        <div className="innerCard cardImgWrapper">
                          <Card
                            cover={
                              <>
                                <Image
                                  src={
                                    item.artist_image
                                      ? item.artist_image
                                      : AvatarImg
                                  }
                                  alt="example"
                                  preview={false}
                                  className="img-fluid "
                                />
                                <span>{item.artist_name}</span>
                              </>
                            }
                            actions={[
                              <IconButton
                                type="primary"
                                handleClick={() => OpenContact(item.artist_id)}
                                text="Contact"
                                className="searchBtn w-auto me-0 mb-3"
                                key="message"
                              />,
                              <IconButton
                                type="primary"
                                handleClick={() => OpenProfile(item.artist_id)}
                                text="View Profile"
                                className="searchBtn w-auto"
                                key="profile"
                              />,
                            ]}
                          >
                          </Card>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Card>
            )}
          </Layout>
        </Layout>
      </div>
    </div>
  );
};

export default ArtistFolder;
