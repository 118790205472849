import { Form, Input, Button, Space, Upload, message,Select } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
const FieldRepeater = ({ initialValues }) => {
  const Option = Select.Option;
  const [form] = Form.useForm();
  const [fields, setFields] = useState(initialValues.fields);
  const [tags, setTags] = useState([]);
  
  useEffect(() => {
    form.setFieldsValue({ fields });
  }, [fields, form]);

  const finish = (values) => {
    console.log({fields});
    return false;

  };
  const handleImageChange = (info, index) => {
    if (info.file.status === 'done') {
      const newFields = [...fields];
      newFields[index].image = info.fileList;
      setFields(newFields);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  return (
    <Form form={form} onFinish={finish} initialValues={initialValues}>
      <Form.List name="fields" className="flex-wrap pt-3">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...field}
                  name={[field.name, 'name']}
                  fieldKey={[field.fieldKey, 'name']}
                  rules={[{ required: true, message: 'Please enter a name' }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>

                <Form.Item
                    name={[field.name, "album_tag_ids"]}
                    label="Album Tags"
                    className="w-100"
                    rules={[
                      {
                        required: true,
                        message: "Please add album tag",
                      },
                    ]}
                >
                    <Select
                        placeholder="What type of tattoos do you provide?"
                        className="formControl"
                        mode="multiple"
                        allowClear
                        
                    >
                        {tags.map((tag) => {
                            return (
                                <Option value={tag} id={tag}>
                                    {tag}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, 'image']}
                  fieldKey={[field.fieldKey, 'image']}
                  valuePropName="fileList"
                  getValueFromEvent={(info) => info.fileList}
                >
                  <Upload
                    name="image"
                    listType="picture-card"
                    showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                    beforeUpload={() => false}
                    onChange={(info) => handleImageChange(info, index)}
                  >
                    { <PlusOutlined />}
                  </Upload>
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary">
            
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FieldRepeater;