import Http from "../Http";

export function register(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
          
                Http.post('api/register', values)
                    .then((res) => {
                        return resolve(res.data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
          
        });
}
