import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Modal, Image, notification } from "antd";
import { TATTOO_URL } from "../../Helper";
import { Link } from "react-router-dom";
import Http from "../../Http";
import { useDispatch } from "react-redux";
import * as Actions from "../../store/actions/user";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { UserImg, TattooMachine, BGImg } from "../../constant/images";
function GoogleCallBack() {
    localStorage.getItem("auth_role");
    const [loading, setLoading] = useState(true);    
    const [isNewModal, setNewModal] = useState(false);
    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const handleOkNew = () => {
      setNewModal(false);
  };

  const handleCancelNew = () => {
      setNewModal(false);
  };
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    Http.get(TATTOO_URL + `api/auth/google_callback${location.search}`, {headers: {"Content-Type": "application/json", Accept: "application/json"}}).then((res) => {
      if (res.data.role == true) {
        if(res.data.profile == true){
          localStorage.setItem('auth_token', res.data.data.token);
          localStorage.setItem('auth_role', res.data.data.role);
          dispatch(Actions.set_user_auth(res.data));
          notification.open({
            message: "Successfully logged in!",
            icon: (
              <CheckCircleOutlined 
                style={{
                  color: '#d62b47',
                }}
              />
            ),
          });
          navigate('/')
        }else{
          localStorage.setItem('auth_token', res.data.data.token);
          localStorage.setItem('auth_role', res.data.data.role);
          dispatch(Actions.set_user_auth(res.data));
          notification.open({
            message: "Successfully logged in!",
            icon: (
              <CheckCircleOutlined 
                style={{
                  color: '#d62b47',
                }}
              />
            ),
          });
          navigate("/create-profile",{state:{loginData: res.data.data}});
        }
      }else{
        if(localStorage.getItem("Role") != null){
          Http.post(TATTOO_URL + `api/userType`, {user_id:res.data.data.user_id,role: localStorage.getItem("Role"), email: res.data.data.email},
          {headers: {"Content-Type": "application/json",Accept: "application/json"}}).then((response) => {
            dispatch(Actions.set_user_auth(response.data));
            setLoading(true);
            setLoading(false);
            if (response.data.data.role == "Customer") {
              navigate("/", {state: {loginData: response.data.data}});
            } else {
              navigate("/create-profile",{state:{loginData: response.data.data}});
            }
          })
        }else{
          setUserId(res.data.data.user_id);
          setUserEmail(res.data.data.email);
          setNewModal(true);
        }
      }
    });  
  }, []); 

  const handleCustomer = (role) => {
    setNewModal(false);
    localStorage.setItem("Role", role)
    Http.post(TATTOO_URL + `api/userType`, {user_id:userId,role: role,email:userEmail},
    {headers: {"Content-Type": "application/json",Accept: "application/json"}}).then((userResponse) => {
      dispatch(Actions.set_user_auth(userResponse.data));
      setLoading(true);
      setLoading(false);
      if (userResponse.data.data.role == "Customer") {
        navigate("/", {state: {loginData: userResponse.data.data}});
      } else {
        navigate("/create-profile",{state:{loginData: userResponse.data.data}});
      }
    })
  }

  
  return(
    <>
      <Modal
          title="Choose us for?"
          open={isNewModal}
          onOk={handleOkNew}
          onCancel={handleCancelNew}
          footer={false}
          className="formModal"
      >
          <div className='modalBody'>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                  <Col xs={12} md={12}>
                      <div className='roleButton'>
                          <div className='roleIcon'>
                              <Link onClick={() => handleCustomer('Customer')} className="roleLink">
                                  <Image className="img-fluid" preview={false} alt="role icon" src={UserImg} />
                              </Link>
                          </div>
                          <h4 className='roleTitle'>Client</h4>
                          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                      </div>
                  </Col>
                  <Col xs={12} md={12}>
                      <div className='roleButton'>
                          <div className='roleIcon'>
                              <Link onClick={() => handleCustomer('Artist')}  className="roleLink">
                                  <Image className="img-fluid" preview={false} alt="role icon" src={TattooMachine} />
                              </Link>
                          </div>
                          <h4 className='roleTitle'>Artist</h4>
                          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                      </div>
                  </Col>
              </Row>
          </div>
      </Modal>
    </>
  )
}
export default GoogleCallBack;