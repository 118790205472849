import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {Spin, notification } from "antd";
import { TATTOO_URL } from "../../Helper";
import Http from "../../Http";
import { useDispatch } from "react-redux";
import * as Actions from "../../store/actions/user";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';


function FacebookCallBack() {
  localStorage.getItem("auth_role");
  const [loading, setLoading] = useState(true);    
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const location = useLocation();
  let userRole = localStorage.getItem("Role")
  useEffect(() => {
    Http.get(TATTOO_URL + `api/auth/facebook_callback${location.search}`, {headers: {"Content-Type": "application/json", Accept: "application/json"}}).then((res) => {
      if (res.data.role == true && res.data.profile == 1) {
          localStorage.setItem('auth_token', res.data.data.token);
          localStorage.setItem('auth_role', res.data.data.role);
          dispatch(Actions.set_user_auth(res.data));
          notification.open({
            message: "Successfully logged in!",
            icon: (
              <CheckCircleOutlined 
                style={{
                  color: '#d62b47',
                }}
              />
            ),
          });
          navigate('/')
        } else if (res.data.profile == 0) {
          dispatch(Actions.set_user_auth(res.data));
          localStorage.setItem('auth_token', res.data.data.token);
          localStorage.setItem('auth_role', res.data.data.role);
            setLoading(true);
            setLoading(false);
            if (res.data.data.role == 'Customer') {
              navigate("/", {state: {loginData: res.data.data}});
            } else {
              navigate("/create-profile",{state:{loginData: res.data.data}});
            }
      }else{
        if(res.data.role == false){
          Http.post(TATTOO_URL + `api/userfbType`, {user_id:res.data.data.user_id,role: localStorage.getItem("Role")},
          {headers: {"Content-Type": "application/json",Accept: "application/json"}}).then((response) => {
            dispatch(Actions.set_user_auth(response.data));
            setLoading(true);
            setLoading(false);
            if (res.data.data.role === 'Customer') {
              navigate("/", {state: {loginData: response.data.data}});
            } else {
              navigate("/create-profile",{state:{loginData: response.data.data}});
            }
          })
        }else{
          dispatch(Actions.set_user_auth(res.data));
          setLoading(true);
          setLoading(false);
          if (userRole === 'Customer') {
            navigate("/", {state: {loginData: res.data.data}});
          } else {
            navigate("/create-profile",{state:{loginData: res.data.data}});
          }
        }
      }
    });  
  }, []); 

 
  {loading ? (
      <div className="spinDiv">
      <Spin size="large" />
      </div>
  ) : (
      <>
        <div>Login Succesffully</div>
      </>
      );
    }
  }
export default FacebookCallBack;