import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Dropdown, Drawer, Menu, Divider } from "antd";
import { Logo } from "../../constant/images";
import { useSelector } from "react-redux";
import { logout } from "../../pages/authentication/logout";
import { FaSignInAlt } from "react-icons/fa";
import { FaPeopleArrows, FaLock, FaEdit, FaHome, FaUser, FaPhoneAlt, FaComments, FaUserAlt } from "react-icons/fa";

function NavBar() {
  const userDetails = useSelector((state) => state?.User?.userDetails);
  const profile = localStorage.getItem("auth_role");
  localStorage.getItem("auth_role");
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const DrawerMenu = () => {
    return (
      <>
        {userDetails?.token ? (
          <>
            <Menu mode="inline" className="" style={{ padding: "4px", color: "#fff", background: '#d62b47', textAlign: 'center' }}>
              <Menu.Item key="1" className="" style={{ minHeight: "120px" }}>
                <div className="container-fluid" style={{}}>
                  <img src={Logo} className="img-fluid" alt="logo" style={{ width: "90px", float: "none" }} />
                </div>
              </Menu.Item>
            </Menu>
            <Menu mode="inline" className="" style={{ padding: "4px", color: "#fff", background: '#d62b47', textAlign: 'center' }}>
              <Menu.Item key="2">
                <Link
                  onClick={(e) => e.preventDefault()}
                  className="link"
                  style={{ textTransform: "capitalize", textAlign: "center" }}
                >
                  <Avatar
                    style={{
                      backgroundColor: "#fff",
                      color: '#000',
                      marginRight: "0.5rem",
                    }}
                  >
                    {userDetails?.name
                      ?.split(" ")
                      .map((word) => word[0])
                      .join("")}
                  </Avatar>
                  Welcome,{" "}
                  {userDetails?.name ? userDetails?.name : userDetails?.role}
                </Link>
                {/* </Dropdown> */}
              </Menu.Item>
            </Menu>
            <Divider />
            <Menu mode="inline" className="" style={{ padding: "4px", color: "#fff", background: '#d62b47', textAlign: 'center' }}>
              <Menu.Item key="3" style={{}}>
                <FaHome className="mx-2" />
                <span>Home</span>
                <Link
                  to={"/"}
                  className="dropLink"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => setVisible(false)}
                ></Link>
              </Menu.Item>
            </Menu>
          </>
        ) : (
          ""
        )}

        <Menu mode="inline" className="" style={{ color: "#fff", background: '#d62b47', textAlign: 'center' }}>
          {userDetails &&
            userDetails?.role &&
            userDetails?.role === "Customer" ? (
            ''
          ) :
            <>
              {userDetails && userDetails?.role &&
                userDetails?.role === "Artist" ? (
                <Menu.Item key="3" style={{}}>
                  <FaUserAlt className="mx-2" />
                  <span>View Profile</span>
                  <Link
                    to={"/user-profile/?user_id=" + userDetails?.user_id}
                    className="dropLink"
                    onClick={() => setVisible(false)}
                  ></Link>
                </Menu.Item>
              ) : (
                ""
              )}
            </>
          }
        </Menu>
        <Menu mode="inline" className="" style={{ color: "#fff", background: '#d62b47', textAlign: 'center' }}>
          {userDetails &&
            userDetails?.role &&
            userDetails?.role === "Customer" ? (
            <>
              <Menu.Item key="3" style={{}}>
                <FaUser className="mx-2" />
                <span>Saved Artists</span>
                <Link
                  to={"/saved-artists/?user_id=" + userDetails?.user_id}
                  className="dropLink"
                  onClick={() => setVisible(false)}
                ></Link>
              </Menu.Item>
              <Menu.Item key="4" style={{}}>
                <FaUser className="mx-2" />
                <span>Past Artists</span>
                <Link
                  to={"/past-artists/?user_id=" + userDetails?.user_id}
                  className="dropLink"
                  onClick={() => setVisible(false)}
                ></Link>
              </Menu.Item>
            </>
          ) :
            <>
              {userDetails && userDetails?.role &&
                userDetails?.role === "Artist" ? (
                <Menu.Item key="3" style={{}}>
                  <FaEdit className="mx-2" />
                  <span>Edit Profile</span>
                  <Link
                    to={"/edit-profile-artist/?user_id=" + userDetails?.user_id}
                    className="dropLink"
                    onClick={() => setVisible(false)}
                  ></Link>
                </Menu.Item>
              ) : (
                ""
              )}
            </>
          }
        </Menu>
        {userDetails?.token ? (
          <Menu mode="inline" className="" style={{ color: "#fff", background: '#d62b47', textAlign: 'center' }}>
            <Menu.Item key="1" style={{}}>
              <FaComments className="mx-2" />
              <span>Message</span>
              <Link
                to={"/inbox/?user_id=" + userDetails?.user_id}
                onClick={() => setVisible(false)}
                className="nav-link"
              ></Link>
            </Menu.Item>
          </Menu>
        ) : (
          <>
            <Menu mode="inline" className="" style={{ padding: "4px", color: "#fff", background: '#d62b47', textAlign: 'center' }}>
              <Menu.Item key="1" className="" style={{ minHeight: "120px" }}>
                <div className="container-fluid">
                  <img src={Logo} className="img-fluid" alt="logo" style={{ width: "90px", float: "none" }} />
                </div>
              </Menu.Item>
            </Menu>
            <Menu mode="inline" className="" style={{ background: '#d62b47', color: "#fff", textAlign: 'center' }}>
              <Menu.Item key="1">
                <FaPeopleArrows className="mx-2" />
                <span>Join Us</span>
                <Link
                  className="nav-link navBtn"
                  to={"/register"}
                  onClick={() => setVisible(false)}
                  reloadDocument
                ></Link>
              </Menu.Item>
            </Menu>
          </>
        )}
        <Menu mode="inline" className="" style={{ background: '#d62b47', color: "#fff", textAlign: 'center' }}>
          <Menu.Item key="1">
            <FaPhoneAlt className="mx-2" />
            <span>Contact Us</span>
            <Link
              className="nav-link"
              to={"/contact-us"}
              onClick={() => setVisible(false)}
            ></Link>
          </Menu.Item>
        </Menu>
        <Divider />
        {userDetails?.token ? (
          <Menu
            mode="inline"
            className="logoutmenu"
            style={{
              padding: "0px",
              borderTop: "1px dashed white",
              position: "absolute",
              bottom: "40px",
              background: '#d62b47',
              color: "#fff",
              textAlign: 'center',
            }}
          >
            <Menu.Item key="5">
              <FaLock className="mx-2" />
              <span>Logout</span>
              <Link
                onClick={logout}
                className="logOutBtn"
                reloadDocument
              ></Link>
            </Menu.Item>
          </Menu>
        ) : (
          ""
        )}
      </>
    );
  };

  const items = [
    {
      label:
        userDetails &&
          userDetails?.role &&
          profile === "Customer" ? (
          <Link
            to={"/saved-artists/?user_id=" + userDetails?.user_id}
            className="dropLink"
            onClick={() => setVisible(false)}
          >
            Saved Artists
          </Link>
        ) : (
          <Link
            to={"/user-profile/?user_id=" + userDetails?.user_id}
            className="dropLink"
            onClick={() => setVisible(false)}
          >
            View Profile
          </Link>
        ),
      key: "1",
    },
    {
      label:
        userDetails &&
          userDetails?.role &&
          profile === "Customer" ? (
          <>
            <Link
              to={"/past-artists/?user_id=" + userDetails?.user_id}
              className="dropLink"
              onClick={() => setVisible(false)}
            >
              Past Artists
            </Link>
          </>
        ) : (
          <Link
            to={"/edit-profile-artist/?user_id=" + userDetails?.user_id}
            className="dropLink"
            onClick={() => setVisible(false)}
          >
            Edit Profile
          </Link>
        ),
      key: "2",
    },

    {
      label: (
        <button onClick={logout} className="logOutBtn">
          Log Out
        </button>
      ),
      key: "3",
    },
  ];

  return (
    <div className="navBar">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link className="navbar-brand" to={"/"}>
            <img src={Logo} className="img-fluid" alt="logo" />
          </Link>
          <button className="navbar-toggler" type="button" onClick={showDrawer}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <Drawer
            placement="left"
            onClose={onClose}
            open={visible}
            className="homebMenu" style={{ background: '#d62b47' }}
          >
            <DrawerMenu className="menuItem" style={{ background: '#d62b47' }} />
          </Drawer>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {userDetails?.token ? (
                <li className="nav-item msgBtn">
                  <Link
                    to={"/inbox/?user_id=" + userDetails?.user_id}
                    className="nav-link"
                  >
                    Messages
                  </Link>
                </li>
              ) : (
                <li className="nav-item">
                  <Link
                    className="nav-link navBtn"
                    to={"/register"}
                    reloadDocument
                  >
                    <span className="iconImg">
                      <FaSignInAlt />
                    </span>
                    Join Us
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link className="nav-link" to={"/contact-us"}>
                  Contact Us
                </Link>
              </li>
              {userDetails?.token ? (
                <li className="nav-item me-3 dropItem">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={["click"]}
                    className="avatarDropdown"
                  >
                    <Link
                      onClick={(e) => e.preventDefault()}
                      className="link"
                      style={{ textTransform: "capitalize" }}
                    >
                      <Avatar
                        style={{
                          backgroundColor: "#d62b47",
                          marginRight: "0.5rem",
                        }}
                      >
                        {userDetails?.name
                          ?.split(" ")
                          .map((word) => word[0])
                          .join("")}
                      </Avatar>
                      Welcome,{" "}
                      {userDetails?.name
                        ? userDetails?.name
                        : userDetails?.role}
                    </Link>
                  </Dropdown>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
