import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Card } from 'antd';
// import { PageBanner } from '../../components/Banner/pageBanner';
import * as privacyService from '../../services/privacyService';
import { useDispatch } from "react-redux";
import * as cookiesService from '../../services/cookiesService';


const CookiesPolicy = () => {
    var dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [cookies, setCookies] = useState([]);
    const [cookiesTem, setCookiesTemp] = useState([]);

    const bannerStyle = {
        backgroundImage: `url(${cookiesTem?.template_photo})`,
        backgroundSize: 'cover',
        height: '450px',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
    const getCookiesPolicy = (setting_name) => {
        dispatch(privacyService.getCookies(setting_name))
            .then((res) => {
                setLoading(true);
                setCookies(res?.data?.data);
                setLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    }
   
    const getCookiesTemService = () => {
        dispatch(cookiesService.getCookiesTemServiceData())
        .then((res) => {
            setLoading(true);

            setCookiesTemp(res?.data);
            setLoading(false);
        })
        .catch((err) => {
            console.info("Get Error Edit State...");
        });
    }

    useEffect(() => {
        getCookiesPolicy();
        getCookiesTemService();
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='faqWrapper'>
            <div className='faqInnerWrap'>
                {/* <PageBanner
                    bannerText="Cookies"
                    colorText="Policy"
                    bannerSubText="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet."
                /> */}
                 <div className='bannerWrapper'>
            <div className='innerWrap'>
                <div className='bannerSec' style={bannerStyle}>
                    <div className='bannerContent'>
                        <h3 className='secTitle'>{cookiesTem?.template_name}
                        {/* <span className='colorText'>{colorText}</span> */}
                        </h3>
                        <p className='subText'>{cookiesTem?.sub_heading}</p>
                    </div>
                </div>
            </div>
        </div>
                <div className='container'>
                    {loading ?
                        (
                            <div className='spinDiv'>
                                <Spin size='large' />
                            </div>
                        ) :
                        (
                            <div className='faqWrap'>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                                    <Col xs={24} md={24} xl={24}>
                                        <Card className='cmsCard' bordered={false} title="Cookies Policy">
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: cookies[0]?.setting_value,
                                                }}
                                            ></p>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
}

export default CookiesPolicy;