import React from "react";
import { Form, Input, Select } from 'antd';

const { Option } = Select;

const StepOne = (props) => {
    console.warn('props', props)

    return (
        <div className='stepWrapper'>
            <div className='stepwrapInner'>
                <div className='stepTitle'>
                    <h4>What is your business address?</h4>
                </div>
                    <Form.Item
                        name='address_line1'
                        label='Address Line 1'
                        rules={[{
                            required: true,
                            message: 'Please enter address here!'
                        }]}
                    >
                        <Input
                            className='formControl'
                            placeholder='Address Line 1'
                            onChange={props.handleInput('address_line1')}
                            defaultValue={props.profileInput.address_line1}
                        />
                    </Form.Item>
                    <Form.Item
                        name='address_line2'
                        label='Address Line 2'
                    >
                        <Input
                            name='address_line2'
                            className='formControl'
                            placeholder='Address Line 2'
                            onChange={props.handleInput('address_line2')}
                            defaultValue={props.profileInput.address_line2}
                        />
                    </Form.Item>
                    <Form.Item
                        name='business_country'
                        label='Country'
                        rules={[{
                            required: true,
                            message: 'Please select country here!'
                        }]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 16px)',
                        }}
                    >
                        <Select
                            showSearch
                            name='business_country'
                            placeholder="Select Country"
                            className="formControl customSelect"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                        optionB.children.toLowerCase()
                                    )
                            }
                            onChange={props.handleInput('business_country')}
                            defaultValue={props.profileInput.business_country ? props.profileInput.business_country : "Select Country"}
                        >
                            {props.country?.map((country) => {
                                return (
                                    <Option value={country.isoCode} key={country.isoCode}>{country.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='state'
                        label='State'
                        rules={[{
                            required: true,
                            message: 'Please select state here!'
                        }]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 0px)',
                            margin: '0 0 0 16px'
                        }}
                    >
                        <Select
                            showSearch
                            name='state'
                            placeholder="Select State"
                            className="formControl customSelect"
                            onChange={props.handleInput('state')}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                        optionB.children.toLowerCase()
                                    )
                            }
                            defaultValue={props.profileInput.state ? props.profileInput.state : 'Select State'}
                        >
                            {props.states?.map((state, index) => {
                                return (
                                    <Option value={state.isoCode} key={index}>{state.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='city'
                        label='City'
                        rules={[{
                            required: true,
                            message: 'Please enter city here!'
                        }]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 16px)',
                        }}

                    >
                        <Select
                            showSearch
                            name='city'
                            placeholder="Select city "
                            className="formControl customSelect"
                            onChange={props.handleInput('city')}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                        optionB.children.toLowerCase()
                                    )
                            }
                            defaultValue={props.profileInput.city ? props.profileInput.city : 'Select city'}
                        >
                            {props.city?.map((city, index) => {
                                return (
                                    <Option value={city.name} key={index}>{city.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='zip_code'
                        label='Zip Code'
                        rules={[
                            {
                            required: true,
                            message: 'Please enter zip code here!'
                        },
                        {
                            pattern: /^[\d]{0,10}$/,
                            message: "Enter valid Zip Code.",
                          },
                        {
                            maxLength: 10,
                            message: "Enter valid Zip Code.",
                          },
                    ]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 0px)',
                            margin: '0 0 0 16px'
                        }}
                    >
                        <Input
                            className='formControl'
                            placeholder='Zip Code'
                            name='zip_code'
                            onChange={props.handleInput('zip_code')}
                            defaultValue={props.profileInput.zip_code}
                        />
                    </Form.Item>
                    <Form.Item
                        name='business_website_address'
                        label='Business Website Address'
                    >
                        <Input
                            className='formControl'
                            name='business_website_address'
                            placeholder='www.example.com'
                            onChange={props.handleInput('business_website_address')}
                            defaultValue={props.profileInput.business_website_address}
                        />
                    </Form.Item>
            </div>
        </div>
    );
}

export default StepOne;