import Http from "../Http";

export function filterList(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.post('api/userprofile/filter_search',value)
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}
