import React from 'react';

export const PrimaryButton = ({ handleClick, text, className }) => {
    return (
        <button className={'btnPrimary ' + className} onClick={handleClick}>
            {text}
        </button>
    );
};

export const IconButton = ({ handleClick, text, className, icon, value, onChange }) => {
    return (
        <button className={'btnPrimary ' + className} onClick={handleClick} value={value} onChange={onChange}>
            {icon} {text}
        </button>
    );
};

export const SecondaryButton = ({ handleClick, text, className, icon }) => {
    return (
        <button className={'btnSecondary ' + className} onClick={handleClick}>
            {text}
        </button>
    );
};