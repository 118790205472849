import Http from "../Http";

export function getPlans() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("sanctum/csrf-cookie").then((response) => {
        Http.get("api/plan/getplans")
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    });
}

export function saveArtistPlan(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("sanctum/csrf-cookie").then((response) => {
        Http.post("api/userprofile/saveartistplan", value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    });
}

export function getArtistPlan(user_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("sanctum/csrf-cookie").then((response) => {
        Http.get("api/getartistplan?user_id=" + user_id.user_id)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    });
}

export function makePlanPayment(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("sanctum/csrf-cookie").then((response) => {
        Http.post("api/makePayment", value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    });
}

export function getPlanDetails(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("sanctum/csrf-cookie").then((response) => {
        Http.post("api/plan/getplandetail", { plan_id: value })
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    });
}
