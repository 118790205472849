import React, { useEffect, useState } from "react";
import {
  Divider,
  Anchor,
  Spin,
  Card,
  Image,
  Rate,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Carousel,
  notification,
  Button,
  Typography,
} from "antd";
import * as profileService from "../../services/createProfile";
import * as messageService from "../../services/sendMessage";
import * as reviewService from "../../services/writeReview";
import * as saveArtist from "../../services/saveArtistDataService";
import { useDispatch, useSelector } from "react-redux";
import { Logo, CardCover, AvatarImg } from "../../constant/images";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { SecondaryButton, IconButton } from "../../components/Buttons/buttons";
import {
  FaRegEnvelope,
  FaRegStar,
  FaRegHeart,
  FaShareSquare,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa";
import {
  IoLocationOutline,
  IoCallOutline,
  IoGlobeOutline,
  IoBusinessOutline,
} from "react-icons/io5";
import { AiOutlineDollar } from "react-icons/ai";
import { MdSocialDistance } from "react-icons/md";
import * as getReviewService from "../../services/editWriteReview";
import { CheckCircleOutlined } from "@ant-design/icons";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
import * as ReviewService from "../../services/requestReview";
import * as SingleReview from "../../services/requestReview";
import PlayIcon from '../../assets/images/PlayIcon.png';
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';
const { Title } = Typography;

const Index = () => {
  const { Meta } = Card;
  const [form] = Form.useForm();
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const location = useLocation();
  const [artistData, setArtistData] = useState();
  const [loadings, setLoadings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [albumOpen, setAlbumOpen] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [albumContent, setAlbumContent] = useState("");
  const [user, setUser] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [newReview, setNewReview] = useState();
  const userDetails = useSelector((state) => state?.User?.userDetails);
  const queryParams = new URLSearchParams(window.location.search);
  const [allReviews, setAllReviews] = useState([]);
  const [visible, setVisible] = useState(false);
  const [averageRating, setAverageRating] = useState([]);
  const userID = queryParams.get("user_id");
  let customerId = localStorage.getItem("customer_id");
  let NewCustId = parseInt(customerId);
  let NewArtistId = parseInt(userID);
  const [reviewExist, setReviewExist] = useState([]);
  const [messageInput, setMessageInput] = useState({
    name: "",
    email: "",
    location: "",
    mobile: "",
    message: "",
  });
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewPortfolio, setViewPortfolio] = useState(false);

  const [reviewInput, setReviewInput] = useState({
    to_name: "",
    review_message: "",
  });

  const role = localStorage.getItem("auth_role");

  const newCustomerId = newReview?.find((reviewId) => reviewId?.user_id === user?.user_id);

  useEffect(() => {
    if (location !== undefined && location.state !== null) {
      let contactFormSHow = location.state.contact;
      if (contactFormSHow === true) {
        setIsModalOpen(true);
      }
    }
  }, [location]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };

  const handleInput = (e) => {
    setMessageInput({ ...messageInput, [e.target.name]: e.target.value });
  };

  const handleReviewInput = (e) => {
    setReviewInput({ ...reviewInput, [e.target.name]: e.target.value });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSaveArtist = () => {
    setLoader(false);
    setOpen(false);
    const values = {};
    values.user_id = user.user_id;
    values.is_default = 1;
    values.artist_id = userID;
    enterLoading(0);
    dispatch(saveArtist.SavedArtistDataService(values))
      .then((res) => {
        setLoader(true);
        setOpen(true)
        fetchArtistData(userID)
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const new_review = artistData?.user_profile?.review[0]?.user_profile_id;

  const showAlbum = () => {
    setAlbumOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAlbumOk = () => {
    setAlbumOpen(false);
  };

  const handleAlbumCancel = () => {
    setAlbumOpen(false);
  };

  const showReviewModal = () => {
    setReviewModal(true);
  };

  const hideReviewModal = () => {
    setReviewModal(false);
  };
  const showPreviewModal = () => {
    setViewPortfolio(true);
  };

  const hidePreviewModal = () => {
    setViewPortfolio(false);
  };

  const onFinish = async (e) => {
    setLoader(true);
    setOpen(true);
    e.preventDefault();
    try {
      await form.validateFields(["message"]);

      const data = {
        name: user?.first_name,
        email: user?.email,
        location: user?.location ? user?.location : messageInput?.location,
        mobile: user?.mobile ? user?.mobile : messageInput?.mobile,
        message: messageInput?.message,
        to_id: artistData?.user_profile.user_id,
        from_id: userDetails?.user_id,
        role: role ? role : userDetails.role[0] ?? userDetails?.role,
      };
      enterLoading(0);
      dispatch(messageService.sendMessage(data))
        .then((res) => {
          notification.open({
            message: "Message sent successfully!",
            icon: (
              <CheckCircleOutlined
                style={{
                  color: "#d62b47",
                }}
              />
            ),
          });
          // return;
          setIsModalOpen(false);
          setLoader(false);
          setOpen(false);
          navigate("/user-profile/?user_id=" + userID);
        })
        .catch((err) => {
          console.info("Get Error Edit State...");
        });
    } catch {
      console.log("Validation error");
    }
  };

  useEffect(() => {
    setLoader(true);
    setOpen(true);
    let data = {
      user_profile_id: userID,
    };
    dispatch(SingleReview.getSingleArtistReviews(data))
      .then((res) => {
        setNewReview(res?.data?.data)
        setAllReviews(res?.data?.data);
        checkAverageRating(res?.data?.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
    setLoader(false);
    setOpen(false);
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo, showAlbum);
  };

  const handleNavigate = () => {
    navigate("/write-review/?user_id=" + userID);
  };
  const handleNavigateEdit = () => {
    navigate("/edit-review/?user_id=" + userID);
  };


  const fetchArtistData = (user_id) => {
    const old_customer_id = localStorage.getItem('customer_id');
    const customer_id = parseInt(old_customer_id);
    setLoader(true);
    setOpen(true);
    dispatch(profileService.getArtistData(user_id, customer_id))
      .then((res) => {
        setArtistData(res?.data);
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const fetchCurrentUser = (user_id) => {
    setLoader(true);
    setOpen(true);
    dispatch(messageService.getUserData(user_id))
      .then((res) => {
        let fav_artist_list = res.data.data.fav_artist_list;
        let isFav = false;
        if (fav_artist_list.includes(parseInt(userID))) {
          isFav = true;
        }
        let userData = {
          ...user,
          user_id: res.data.data.user_id,
          name: res.data.data.first_name,
          email: res.data.data.email,
          mobile: res.data.data.mobile,
          location: res.data.data.location,
          isFav: isFav,
        };
        setUser(userData);
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const handleAlbumClick = (item1) => {
    setLoader(true);
    setOpen(true);
    //setAlbumOpen(true);
    // setVisible(true);
    showPreviewModal(true);
    artistData?.albums?.filter((item) =>
      item.id === item1 ? setAlbumContent(item) : ""
    );
    setLoader(false);
    setOpen(false);
  };

  const getReviewsList = (user_id) => {
    dispatch(reviewService.getReviews(user_id))
      .then((res) => {
        setReviewList(res.data.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true);
    setOpen(true);
    fetchArtistData(userID);
    getReviewsList(userID);
    fetchCurrentUser(userDetails?.user_id);
    window.scrollTo(0, 0);
    if (userDetails?.user_id) {
      localStorage.setItem("customer_id", userDetails?.user_id);
    }
    setLoader(false);
    setOpen(false);
  }, []);

  const onFinishReview = () => {
    setLoader(true);
    setOpen(true);
    const data = {
      to: reviewInput.to_name,
      messages: reviewInput.review_message,
      profile_url: window.location.href,
    };
    dispatch(ReviewService.requestReview(data))
      .then((res) => {
        notification.open({
          message: "Review request sent successfully!",
          icon: (
            <CheckCircleOutlined
              style={{
                color: "#d62b47",
              }}
            />
          ),
        });
        //return;
        showReviewModal(false);
        setReviewModal(false);
        setLoader(false);
        setOpen(false);
        navigate("/user-profile/?user_id=" + userDetails?.user_id);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const checkReview = () => {
    setLoader(true);
    setOpen(true);
    let value = {
      user_profile_id: NewArtistId,
      user_id: NewCustId,
    };
    dispatch(getReviewService.editgetReviews(value))
      .then((res) => {
        setReviewExist(res.data);
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoader(false);
        setOpen(false);
      });
  };

  const handleDelete = (item) => {
    Swal.fire({
      title: "Delete Review?",
      text: "Are you sure you want to delete this review!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ok",
      dangerMode: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        setOpen(true);
        let Value = {
          id: item.id
        };
        dispatch(getReviewService.deleteReviews(Value))
          .then((res) => {
            window.location.reload();
          })
      }
    });
  };

  const handleEdit = (item) => {
    navigate("/edit-review/?user_id=" + item.user_id);
  }

  useEffect(() => {
    checkReview();
  }, []);

  const removeSavedArtist = () => {
    setLoader(true);
    setOpen(true);
    let artist_id = userID;
    let customer_id = user.user_id;
    dispatch(profileService.removeFavouriteArtist({ artist_id, customer_id }))
      .then((res) => {
        let userData = { ...user };
        userData.isFav = false;
        setUser(userData);
        fetchArtistData(userID)
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const checkAverageRating = (reveiws) => {
    let ratings = 0;
    reveiws.forEach((review) => {
      ratings += review.rating;
    });
    let unFormmatedRating = ratings / reveiws?.length;
    let finalRating = Math.round(unFormmatedRating);
    setAverageRating(finalRating);
  };
  let Key = Math.floor(Math.random() * 1000);

  return (
    <div className="userProfileWrapper">
      {loader === true ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <Spin size="large" />
        </Backdrop>
      ) : (
        <></>
      )}
      <div className="profileWrapInner">
        <div className="container">
          <div className="sliderWrapper">
            <div className="slideWrap">
              {artistData?.user_profile?.slider_media_id ? (
                <Carousel autoplay>
                  {artistData?.user_profile?.slider_media_id?.map(
                    (slide, index) => {
                      return (
                        <div className="slides slide-images" key={index}>
                          <Image
                            preview={false}
                            src={slide ? slide.url : CardCover}
                            alt="slides"
                          />
                        </div>
                      );
                    }
                  )}
                </Carousel>
              ) : (
                <Image preview={false} src={CardCover} alt="slides" />
              )}
            </div>
          </div>
          <div className="infoWrapper">
            <div className="infoWrapInner">
              <Card bordered={false} className="infoCard">
                <div className="cardBody">
                  <div className="infoSection">
                    <div className="information">
                      <div className="imgSection">
                        <Image
                          src={
                            artistData?.user_profile?.profile_pic_url
                              ? artistData?.user_profile?.profile_pic_url
                              : Logo
                          }
                          className="img-fluid"
                          alt="image"
                          preview={false}
                        />
                      </div>
                      <div className="nameSection">
                        <h3 className="name">
                          {artistData?.user_profile?.artist_name}
                        </h3>
                        <p>{artistData?.user_profile?.category_name}</p>
                        {allReviews === null || allReviews === undefined ? (
                          <span className="small">No reviews yet!</span>
                        ) : (
                          <>
                            <Rate
                              disabled
                              defaultValue={averageRating}
                              key={Key}
                            />{" "}
                            <span className="small">
                              ({allReviews.length} Reviews)
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    {userDetails?.token ? (
                      <div className="buttonSec outerBtn">
                        {artistData?.id === userDetails?.user_id ? (
                          <div className="itemgetReview">
                            <IconButton
                              type="primary"
                              handleClick={showReviewModal}
                              text="Get Reviews"
                              className="searchBtn w-auto bgDark"
                              icon={<FaRegStar />}
                            />{" "}
                          </div>
                        ) : (
                          ""
                        )}
                        {artistData?.id === userDetails?.user_id ? (
                          ""
                        ) : (
                          <>
                            {role && role === "Customer" ? (
                              reviewExist?.length !== 0 ? (
                                <div className="itemgetReview">
                                  <IconButton
                                    type="primary"
                                    handleClick={() => handleNavigateEdit()}
                                    text="View/Edit Review"
                                    className="searchBtn w-auto bgDark"
                                    icon={<FaRegStar />}
                                  />
                                </div>
                              ) : (
                                <div className="itemgetReview">
                                  <IconButton
                                    type="primary"
                                    handleClick={() => handleNavigate()}
                                    text="Write a Review"
                                    className="searchBtn w-auto bgDark"
                                    icon={<FaRegStar />}
                                  />
                                </div>
                              )
                            ) : (
                              ""
                            )}

                            {role === "Customer" &&
                              <div className="itemgetReview">
                                <IconButton
                                  type="primary"
                                  handleClick={() => showModal(userID)}
                                  text="Send Message"
                                  className="searchBtn w-auto bgDark"
                                  icon={<FaRegEnvelope />}
                                />
                              </div>
                            }
                          </>
                        )}
                        <div className="itemgetReview small-btn">
                          <IconButton
                            type="primary"
                            handleClick={console.log()}
                            className="searchBtn w-auto bgDark"
                            icon={<FaShareSquare />}
                          />
                        </div>
                        {role === "Customer" ? (
                          artistData?.saved_artist !== 1 ? (
                            <div className="itemgetReview small-btn">
                              <IconButton
                                type="primary"
                                handleClick={() => handleSaveArtist()}
                                className="searchBtn w-auto bgDark"
                                icon={<FaRegHeart />}
                              />
                            </div>
                          ) : (
                            <div className="itemgetReview small-btn">
                              <IconButton
                                type="primary"
                                handleClick={() => removeSavedArtist()}
                                className="searchBtn w-auto bgRed"
                                icon={<FaRegHeart />}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        )}

                        {/* {user && role === "Customer" && !user.isFav && (
                          <div className="itemgetReview small-btn">
                            <IconButton
                              type="primary"
                              handleClick={() => handleSaveArtist()}
                              className="searchBtn w-auto bgDark"
                              icon={<FaRegHeart />}
                            />
                          </div>
                        )} */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="tabSection">
            <div className="linkGroup">
              <Anchor
                items={[
                  {
                    key: "about",
                    href: "#about",
                    title: "About Us",
                  },
                  {
                    key: "Portfolio",
                    href: "#portfolio",
                    title: "Portfolio",
                  },
                  {
                    key: "business",
                    href: "#business",
                    title: "Business",
                  },
                  {
                    key: "certifications",
                    href: "#certifications",
                    title: "Certifications",
                  },
                  {
                    key: "reviews",
                    href: "#reviews",
                    title: "Reviews",
                  },
                ]}
              />
            </div>
          </div>
          <div className="aboutWrapper" id="about">
            <div className="aboutWrapInner">
              <Card className="businessCard" bordered={false}>
                <p>{artistData?.user_profile?.additional_details}</p>
              </Card>
            </div>
          </div>
          <div className="businessWrapper" id="business">
            <Divider orientation="left" className="dividerTitle">
              Business
            </Divider>
            <div className="businessWrapInner">
              <Card className="businessCard" bordered={false}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} md={8} xl={8}>
                    <div className="bsDetails">
                      <span className="bsDetailsIcon me-2">
                        <IoBusinessOutline />
                      </span>
                      <div className="innerDetails">
                        <p>Business Name</p>
                        <span className="text-muted text-capitalize">
                          {artistData?.user_profile?.artist_name}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <div className="bsDetails">
                      <span className="bsDetailsIcon me-2">
                        <IoCallOutline />
                      </span>
                      <div className="innerDetails">
                        <p>Phone Number</p>
                        <span className="text-muted">
                          {artistData?.user_profile?.ph_ext}-
                          {artistData?.user_profile?.phone_number}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <div className="bsDetails">
                      <span className="bsDetailsIcon me-2">
                        <IoGlobeOutline />
                      </span>
                      <div className="innerDetails">
                        <p>Website</p>
                        <span className="text-muted">
                          <Link
                            to={
                              "//" +
                              artistData?.user_profile?.business_website_address
                            }
                            className="link"
                            target="_blank"
                          >
                            {artistData?.user_profile?.business_website_address}
                          </Link>
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <div className="bsDetails">
                      <span className="bsDetailsIcon me-2">
                        <IoLocationOutline />
                      </span>
                      <div className="innerDetails">
                        <p>Address</p>
                        <span className="text-muted text-capitalize">
                          {artistData?.user_profile?.address_line1}{" "}
                          {artistData?.user_profile?.city}{" "}
                          {artistData?.user_profile?.state}{" "}
                          {artistData?.user_profile?.country}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <div className="bsDetails">
                      <span className="bsDetailsIcon me-2">
                        <AiOutlineDollar />
                      </span>
                      <div className="innerDetails">
                        <p>Hourly rate</p>
                        <span className="text-muted">
                          {artistData?.user_profile?.currency}{" "}
                          {artistData?.user_profile?.from_price} -{" "}
                          {artistData?.user_profile?.currency}{" "}
                          {artistData?.user_profile?.to_price}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <div className="bsDetails">
                      <span className="bsDetailsIcon me-2">
                        <MdSocialDistance />
                      </span>
                      <div className="innerDetails">
                        <p>Socials</p>
                        <span className="text-muted me-2">
                          <FaFacebookF />
                        </span>
                        <span className="text-muted">
                          <FaInstagram />
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
          <div className="portfolioWrapper" id="portfolio">
            <Divider orientation="left" className="dividerTitle">
              Portfolio
            </Divider>
            <div className="portfolioWrap">
              <Row gutter={[24, 24]}>
                {artistData?.albums?.length > 0 ? (
                  artistData?.albums?.map((item, index) => {
                    const imageMedia = item.mediaupload.find((media) => media.type === "photo");
                    const firstMedia = item.mediaupload[0];
                    const Media =
                      (imageMedia && imageMedia.type === "photo") ? imageMedia?.media_url :
                        (firstMedia && firstMedia.type === "photo")
                          ? firstMedia?.media_url
                          : (imageMedia && imageMedia.type === "video") ||
                            (firstMedia && firstMedia.type === "video")
                            ? PlayIcon
                            : CardCover;
                    return (
                      <Col xs={24} md={8} xl={8} key={index}>
                        <div className="albumCard">
                          <div className="albumImg">
                            <Image
                              preview={{ visible: false }}
                              onClick={() => handleAlbumClick(item.id)}
                              alt="portfolio"
                              src={Media}
                            />
                          </div>
                          <p className="albumName">{item.album_name}</p>
                        </div>
                        <div
                          style={{
                            display: "none",
                          }}
                        >
                          <Image.PreviewGroup
                            preview={{
                              visible,
                              onVisibleChange: (vis) => setVisible(vis),
                            }}
                          >
                            {albumContent?.mediaupload?.map((albumImg) => {
                              return <Image src={albumImg?.media_url} />;
                            })}
                          </Image.PreviewGroup>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <div>
                    <p style={{ marginLeft: "46px" }}>
                      No Portifolio Available
                    </p>
                  </div>
                )}
              </Row>
            </div>
          </div>

          <div className="credentialsWrapper" id="certifications">
            <Divider orientation="left" className="dividerTitle">
              Certifications
            </Divider>
            {artistData?.user_profile?.certificate ? (
              <div className="credentialsWrapInner">
                <div className="crGroup">
                  <Row gutter={[24, 24]}>
                    {artistData?.user_profile?.certificate?.map(
                      (item, index) => {
                        return (
                          <Col xs={24} md={6} xl={6} key={index}>
                            <Card
                              bordered={false}
                              className="portfolioCard"
                              cover={<img alt="portfolio" src={item?.url} />}
                            >
                              <Meta title={item?.certificate_name} />
                            </Card>
                          </Col>
                        );
                      }
                    )}
                  </Row>
                </div>
              </div>
            ) : (
              <div>
                <p style={{ marginLeft: "37px" }}>
                  No Certifications Available
                </p>
              </div>
            )}
          </div>

          <div className="reviewsWrapper" id="reviews">
            <Divider orientation="left" className="dividerTitle">
              Reviews
            </Divider>
            {allReviews?.length === 0 ? (
              <div>
                <p style={{ marginLeft: "37px" }}>No Review Available</p>
              </div>
            ) : (
              <>
                <div className="reviewsWrapInner">
                  <div className="reviewTopSec">
                    <Card bordered={false} className="reviewSecCard">
                      <div className="cardBody">
                        <div className="headingSection">
                          <h5>
                            {allReviews?.user_profile?.review.length === 0
                              ? 0
                              : allReviews?.user_profile?.review.length}{" "}
                            Reviews for{" "}
                            <span className="text-capitalize">
                              {allReviews?.user_profile?.artist_name}
                            </span>
                          </h5>
                        </div>
                        <div className="contentSection mt-4">
                          <Row gutter={24} align="middle">
                            <Col xs={24} md={8}>
                              <div className="leftSec text-center">
                                <h2>{averageRating ? averageRating : ""}.0</h2>
                                <Rate
                                  disabled
                                  defaultValue={
                                    averageRating ? averageRating : ""
                                  }
                                  key={Key}
                                />
                                <p>Based on {allReviews.length} rating(s)</p>
                              </div>
                            </Col>
                            {/* <Col xs={24} md={10}>
                                                            <div className='rightSec'>
                                                                <div className='rateSec'>
                                                                    <span className='firstText'>Work Quality</span>
                                                                    <Rate disabled defaultValue={5} />
                                                                    <span className='lastText'>5.0</span>
                                                                </div>
                                                                <div className='rateSec'>
                                                                    <span className='firstText'>Communication</span>
                                                                    <Rate disabled defaultValue={5} />
                                                                    <span className='lastText'>5.0</span>
                                                                </div>
                                                                <div className='rateSec'>
                                                                    <span className='firstText'>Value</span>
                                                                    <Rate disabled defaultValue={5} />
                                                                    <span className='lastText'>5.0</span>
                                                                </div>
                                                            </div>
                                                        </Col> */}
                          </Row>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="commentsRow">
                    {allReviews?.map((item, index) => {
                      return (
                        <div className="commentsWrap" key={index}>
                          <div className="commentsInner">
                            <div className="rvAvatar">
                              <Image
                                src={AvatarImg}
                                className="img-fluid"
                                alt="image"
                                preview={false}
                              />
                            </div>
                            <div className="innerCom">
                              <p className="comName">{item?.name}</p>
                              <Rate disabled defaultValue={item?.rating} />
                              <p className="comDesc">{item?.review}</p>
                              <p className="postDate">
                                {moment(item?.date).format("MMMM DD, YYYY")}
                              </p>
                            </div>
                            <div className="actionBtnWrapper">
                              {new_review == customerId && role === "Artist" && (
                                newReview.length !== 0 && (
                                  <div className="editBtn">
                                    <Button
                                      className="w-auto h-auto"
                                      onClick={() => handleEdit(item)}
                                    >
                                      <EditOutlined />
                                    </Button>
                                  </div>
                                )
                              )}

                              {(new_review == customerId && role === "Artist") || (role === "Customer" && newCustomerId?.user_id === item?.user_id) ?
                                <div className="dltBtn">
                                  <Button
                                    className="w-auto h-auto"
                                    onClick={() => handleDelete(item)}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </div>
                                : ""}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>

          <Modal
            title="Connect with artist"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            className="formModal"
            width={780}
          >
            <div className="modalBody">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col xs={12} md={24}>
                  <div className="toSection">
                    <p className="secLable">
                      <label>To:</label>
                    </p>
                    <div className="toWrap">
                      <div className="imgSec">
                        <Image
                          src={
                            artistData?.user_profile?.profile_photo_id
                              ? artistData?.user_profile?.profile_photo_id
                              : Logo
                          }
                          className="img-fluid"
                          preview={false}
                          alt="image"
                        />
                      </div>
                      <div className="detailsWrap">
                        <p className="toName">
                          {artistData?.user_profile?.artist_name}
                        </p>
                        <Rate
                          disabled
                          defaultValue={
                            artistData?.user_profile?.review.length === 0
                              ? 0
                              : artistData?.user_profile?.review[0].rating
                          }
                        />{" "}
                        <span className="small">
                          (
                          {artistData?.user_profile?.review.length === 0
                            ? artistData?.user_profile?.review.length
                            : 0}{" "}
                          reviews)
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col xs={24} md={24}>
                  <Form
                    name="messageForm"
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                  >
                    <Row gutter={16}>
                      <Col xs={24} md={12}>
                        <Form.Item name="name" label="Name">
                          <Input
                            name="name"
                            placeholder="Name"
                            className="formControl"
                            defaultValue={user?.name}
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item name="email" label="Email">
                          <Input
                            name="email"
                            placeholder="Email"
                            className="formControl"
                            defaultValue={user?.email}
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item name="location" label="Location">
                          <Input
                            name="location"
                            placeholder="Location"
                            className="formControl"
                            defaultValue={user?.location}
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="mobile"
                          label="Mobile No."
                          rules={[
                            {
                              required: true,
                              message: "Mobile number field required",
                            },
                            {
                              pattern: /^[\d]{0,15}$/,
                              message: "Enter valid mobile number.",
                            },
                            {
                              maxLength: 15,
                              message: "Please enter valid mobile number.",
                            },
                          ]}
                        >
                          <Input
                            name="mobile"
                            placeholder="Mobile No."
                            className="formControl"
                            defaultValue={
                              user?.mobile ? user?.mobile : messageInput?.mobile
                            }
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24}>
                        <Form.Item
                          name="message"
                          label="Message"
                          rules={[
                            {
                              required: true,
                              message: "Message field required",
                            },
                            {
                              type: "string",
                              warningOnly: true,
                            },
                          ]}
                        >
                          <Input.TextArea
                            rows={5}
                            maxLength={2000}
                            placeholder="Message"
                            className="formControl"
                            name="message"
                            defaultValue={messageInput.message}
                            onChange={handleInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24}>
                        <div className="privacyLinkRow">
                          <p>
                            By clicking or tapping "Send", I agree to the
                            Finding Ink{" "}
                            <Link to={"/terms-and-condition"} className="link">
                              Terms of Use
                            </Link>
                            . The Finding Ink{" "}
                            <Link to={"/privacy-policy"} className="link">
                              Privacy Policy
                            </Link>{" "}
                            applies.
                          </p>
                        </div>
                      </Col>
                      <Col xs={24} md={24}>
                        <Form.Item className="text-end">
                          <SecondaryButton
                            type="primary"
                            handleClick={handleCancel}
                            text="Cancel"
                            className="w-auto"
                          />
                          <Button
                            type="primary"
                            onClick={(e) => onFinish(e)}
                            loading={loadings[0]}
                            className="submitBtn w-auto"
                          >
                            Send
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Modal>

          <Modal
            title={albumContent.album_name}
            open={albumOpen}
            onOk={handleAlbumOk}
            onCancel={handleAlbumCancel}
            footer={false}
            className="formModal"
            width={780}
          >
            <div className="modalBody">
              <Row gutter={16} justify="center">
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: (vis) => setVisible(vis),
                  }}
                >
                  {albumContent?.mediaupload?.map((albumImg) => {
                    return <Image src={albumImg?.media_url} />;
                  })}
                </Image.PreviewGroup>
              </Row>
            </div>
          </Modal>

          {/* Request Review Modal */}
          <Modal
            title="Request A Review"
            open={reviewModal}
            onOk={hideReviewModal}
            onCancel={hideReviewModal}
            footer={false}
            className="formModal"
            width={780}
          >
            <div className="modalBody">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col xs={12} md={24}>
                  <Form
                    name="reviewForm"
                    onFinish={onFinishReview}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                  >
                    <Row gutter={16}>
                      <Col xs={24} md={24}>
                        <Form.Item
                          name="to_name"
                          label="To"
                          rules={[
                            {
                              required: true,
                              message: "Please enter client email",
                              // pattern: new RegExp("/\S+@\S+\.\S+/"),
                            },
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter client email"
                            className="formControl"
                            name="to_name"
                            defaultValue={reviewInput.to_name}
                            onChange={handleReviewInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24}>
                        <Form.Item
                          name="review_message"
                          label="Message"
                          rules={[
                            {
                              required: true,
                              message: "Please enter message",
                            },
                          ]}
                        >
                          <Input.TextArea
                            rows={5}
                            placeholder="Message"
                            className="formControl"
                            name="review_message"
                            defaultValue={reviewInput.review_message}
                            onChange={handleReviewInput}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24}>
                        <Form.Item className="text-end">
                          <SecondaryButton
                            type="primary"
                            handleClick={hideReviewModal}
                            text="Cancel"
                            className="w-auto"
                          />
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadings[0]}
                            className="submitBtn w-auto"
                          >
                            Send
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Modal>

          {/* Image Preview Modal */}
          <Modal
            title="View Portfolio"
            open={viewPortfolio}
            onOk={hidePreviewModal}
            onCancel={hidePreviewModal}
            footer={false}
            className="formModal"
            width={780}
          >
            <div className="modal-body">
              <Row gutter={16}>
                <Col xs={24}>
                  {artistData?.albums?.length > 0 ?
                    (
                      <div className="imageWrapper">
                        <Image.PreviewGroup
                        // preview={{
                        //   visible,
                        //   onVisibleChange: (vis) => setVisible(vis),
                        // }}
                        >
                          <Row>
                            <div className="imgSection">
                              <Title level={4}>Images</Title>
                              <hr />
                              {albumContent?.mediaupload?.map(
                                (file, i) =>
                                  file.type === "photo" && (
                                    <Image
                                      src={file?.media_url}
                                      alt="not found"
                                      className="img-fluid"
                                    />
                                  )
                              )}
                            </div>
                          </Row>
                          <Row>
                            <div className="videoSection">
                              <Title level={4}>Videos</Title>
                              <hr />
                              {albumContent?.mediaupload?.map(
                                (file, i) =>
                                  file.type === "video" && (
                                    <video width="320" height="240" controls>
                                      <source
                                        src={file?.media_url}
                                        type="video/mp4"
                                      />
                                    </video>
                                  )
                              )}
                            </div>
                          </Row>
                        </Image.PreviewGroup>
                      </div>
                    )
                    : (<p>No media available for this album.</p>)
                  }
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Index;
