import './styles/style.scss'
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from "react";
import { Layout, FloatButton } from "antd";
import { BrowserRouter } from "react-router-dom";
import Header from '../src/components/Header'
import Footer from '../src/components/Footer'
import AppRoute from "./routes/routes";
import { UpOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import * as UserDataService from "./services/GetUserData";
import * as Actions from "./store/actions/user";
import UpdateForm from './UpdateForm'

const { Content } = Layout;

function App() {
    var dispatch = useDispatch();
    const userDetails = useSelector((state) => state?.User?.userDetails);


    const getUserDetails = () => {
        dispatch(UserDataService.getUserData())
            .then((res) => {
                dispatch(Actions.set_user_auth(res));
            })
            .catch((err) => {
                alert("error");
            });
    };

    useEffect(() => {
        let localTokenItem = localStorage.getItem("auth_token");
        if (localTokenItem) {
            if (!userDetails || userDetails === {}) {
                getUserDetails();
            }
        } else {
            // window.location.href = "/logout/";
        }
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        // <UpdateForm />
        <BrowserRouter>
            <Layout>
                <Header />
                <Content>
                    <AppRoute />
                </Content>
                <Footer />
                <FloatButton.BackTop shape="square" icon={<UpOutlined />} />
            </Layout>
        </BrowserRouter>
    );
}

export default App;
