import Http from "../Http";

export function writeReview(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.post('api/sendReview', values)
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}

export function getReviews(user_id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.get('api/userprofile/getreviewList?user_id='+ user_id)
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}

export function editwriteReview(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.post('api/editReview', values)
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}

export function editgetReviews(user_id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.get('api/userprofile/getArtistReviews?user_id='+ user_id)
                    .then((res) => {
                        return resolve(res);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}