import Http from "../Http";

export function getUserData(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('sanctum/csrf-cookie').then(response => {
                Http.post('api/get_current_userdata')
                    .then((res) => {
                        return resolve(res.data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        });
}