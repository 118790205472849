import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from '../pages/authentication/login';
import RegisterPage from '../pages/authentication/register';
import ForgotPage from '../pages/authentication/forgotPassword';
import ResetPasswordPage from '../pages/authentication/resetPassword';
import EmailSent from '../pages/authentication/emailSent';
import Homepage from '../pages/homepage/index';
import ContactPage from '../pages/contactUs/index';
import FaqPage from '../pages/faqs/index';
import WriteReviewPage from '../pages/writeReviews/index';
import EditReviewPage from '../pages/writeReviews/editReview';
import UserProfilePage from '../pages/userProfile/index';
import PricingPage from '../pages/pricing/index';
import AboutBusinessPage from '../pages/aboutBusiness/index';
import GetStarted from "../pages/aboutBusiness/getStarted/getStarted";
import InboxPage from "../pages/inbox/index";
import EditProfilePage from "../pages/userProfile/editProfile/editProfile";
import EditProfileArtist from "../pages/userProfile/editProfile/editArtistProfile";
import ViewMessgae from "../pages/inbox/viewMessage";
import PrivacyPolicy from "../pages/privacyPolicy";
import CookiesPolicy from "../pages/privacyPolicy/cookiesPolicy";
import Terms from "../pages/privacyPolicy/terms";
import CheckoutForm from "../pages/pricing/checkoutForm";
import SavedArtist from '../pages/savedArtist'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PastArtist from "../pages/PastArtist/index";
import ViewArtistFolder from "../pages/savedArtist/viewImageArtistFolder";
import GoogleCallBack from "../pages/authentication/GoogleCallBack";
import FacebookCallBack from "../pages/authentication/FacebookCallBack";

const AppRoute = () => {

  const stripePromise = loadStripe('pk_test_eHaaJNlQIKsXM0MlL6WrRaA20028FitRPZ');
    return (
      <Elements stripe={stripePromise}>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/register" element={<RegisterPage />}/>
          <Route exact path="/forgot-password" element={<ForgotPage />} />
          <Route exact path="/reset-password" element={<ResetPasswordPage />} />
          <Route exact path="/email-sent" element={<EmailSent />} />
          <Route exact path="/contact-us" element={<ContactPage />} />
          <Route exact path="/faqs" element={<FaqPage />} />
          <Route exact path="/write-review" element={<WriteReviewPage />} />
          <Route exact path="/user-profile" element={<UserProfilePage />} />
          <Route exact path="/pricing" element={<PricingPage />} />
          <Route exact path="/create-profile" element={<AboutBusinessPage />} />
          <Route exact path="/create-profile-start" element={<GetStarted />} />
          <Route exact path="/inbox" element={<InboxPage />} />
          <Route exact path="/edit-profile" element={<EditProfilePage />} />
          <Route exact path="/edit-profile-artist" element={<EditProfileArtist />} />
          <Route exact path="/inbox/view-message" element={<ViewMessgae />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/cookies-policy" element={<CookiesPolicy />} />
          <Route exact path="/terms-and-condition" element={<Terms />} />
          <Route exact path="/checkout" element={<CheckoutForm />} />
          <Route exact path="/saved-artists/save-artist-folder/:id" element={<ViewArtistFolder />} />
          <Route exact path="/past-artists" element={<PastArtist />} />
          <Route exact path="/saved-artists" element={<SavedArtist />} />
          <Route exact path="/auth/google" element={<GoogleCallBack />} />
          <Route exact path="/auth/facebook" element={<FacebookCallBack />} />
          <Route exact path="/edit-review" element={<EditReviewPage />} />
        </Routes>
       </Elements>
      
    );
}

export default AppRoute